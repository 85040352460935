// Enhanced version of instagramUtils.js

const calculateStoryViews = (averageViews) => {
  // For larger accounts, stories often get 50-70% of their average post views
  // This is more accurate than using a fixed percentage of followers
  return Math.round(averageViews * (0.5 + (Math.random() * 0.2)));
};

const calculateAverages = (posts) => {
  if (!Array.isArray(posts) || posts.length === 0) {
    return {
      globalAverageViews: 0,
      globalEngagementRate: 0.03,
      reels: { count: 0, views: 0, likes: 0, comments: 0, shares: 0 },
      carousels: { count: 0, views: 0, likes: 0, comments: 0, shares: 0 },
      posts: { count: 0, views: 0, likes: 0, comments: 0, shares: 0 },
      reelViews: 0,
      carouselViews: 0,
      singlePhotoViews: 0,
      engagement: {
        overall: 0,
        reels: 0,
        photos: 0,
        carousels: 0
      }
    };
  }

  // Initialize counters for each type
  const reels = { count: 0, views: 0, likes: 0, comments: 0, shares: 0 };
  const carousels = { count: 0, views: 0, likes: 0, comments: 0, shares: 0 };
  const singlePosts = { count: 0, views: 0, likes: 0, comments: 0, shares: 0 };
  let totalViews = 0;
  let totalEngagement = 0;
  let totalPosts = 0;

  // Process each post
  posts.forEach(post => {
    const views = post.mediaType === 2 ? (post.playCount || 0) : Math.round((post.likeCount || 0) / 0.03);
    
    // Add to global totals
    totalViews += views;
    totalEngagement += (post.likeCount || 0) + (post.commentCount || 0) + (post.shareCount || 0);
    totalPosts++;

    // Add to specific type totals
    if (post.mediaType === 2) { // Reel/Video
      reels.count++;
      reels.views += views;
      reels.likes += post.likeCount || 0;
      reels.comments += post.commentCount || 0;
      reels.shares += post.shareCount || 0;
    } else if (post.mediaType === 8) { // Carousel
      carousels.count++;
      carousels.views += views;
      carousels.likes += post.likeCount || 0;
      carousels.comments += post.commentCount || 0;
      carousels.shares += post.shareCount || 0;
    } else { // Single photo post
      singlePosts.count++;
      singlePosts.views += views;
      singlePosts.likes += post.likeCount || 0;
      singlePosts.comments += post.commentCount || 0;
      singlePosts.shares += post.shareCount || 0;
    }
  });

  // Calculate engagement rates
  const overallEngagement = totalPosts > 0 ? (totalEngagement / totalPosts / totalViews) * 100 : 0;
  const reelsEngagement = reels.count > 0 ? ((reels.likes + reels.comments + reels.shares) / reels.count / reels.views) * 100 : 0;
  const photosEngagement = singlePosts.count > 0 ? ((singlePosts.likes + singlePosts.comments + singlePosts.shares) / singlePosts.count / singlePosts.views) * 100 : 0;
  const carouselsEngagement = carousels.count > 0 ? ((carousels.likes + carousels.comments + carousels.shares) / carousels.count / carousels.views) * 100 : 0;

  // Calculate averages
  const globalAverageViews = totalPosts > 0 ? Math.round(totalViews / totalPosts) : 0;
  const reelViews = reels.count > 0 ? Math.round(reels.views / reels.count) : globalAverageViews;
  const carouselViews = carousels.count > 0 ? Math.round(carousels.views / carousels.count) : globalAverageViews;
  const singlePhotoViews = singlePosts.count > 0 ? Math.round(singlePosts.views / singlePosts.count) : globalAverageViews;

  // If we only have data for some types, use their average for missing types
  const availableViews = [reelViews, carouselViews, singlePhotoViews].filter(views => views > 0);
  const averageFromAvailable = availableViews.length > 0 
    ? Math.round(availableViews.reduce((sum, views) => sum + views, 0) / availableViews.length)
    : globalAverageViews;

  return {
    globalAverageViews: averageFromAvailable,
    reelViews: reelViews || averageFromAvailable,
    carouselViews: carouselViews || averageFromAvailable,
    singlePhotoViews: singlePhotoViews || averageFromAvailable,
    engagement: {
      overall: overallEngagement,
      reels: reelsEngagement,
      photos: photosEngagement,
      carousels: carouselsEngagement
    }
  };
};

const calculateEngagementRate = (posts) => {
  if (!Array.isArray(posts) || posts.length === 0) {
    return { overall: 0.03 }; // Default 3% if no data
  }

  let totalEngagementRate = 0;
  let validPosts = 0;

  posts.forEach(post => {
    // Calculate total actions (likes + comments + shares)
    const totalActions = (post.likeCount || 0) + 
                        (post.commentCount || 0) + 
                        (post.shareCount || 0);

    // Get view count based on post type
    let viewCount;
    if (post.mediaType === 2) { // Reel
      viewCount = post.playCount || 0;
    } else { // Photos and Carousels - estimate views from impressions or reach
      viewCount = post.impressions || post.reach || 0;
    }

    // Only calculate if we have valid view count
    if (viewCount > 0) {
      const postEngagementRate = totalActions / viewCount;
      totalEngagementRate += postEngagementRate;
      validPosts++;
    }
  });

  // Calculate average engagement rate across all valid posts
  const overallEngagementRate = validPosts > 0 ? totalEngagementRate / validPosts : 0.03;

  return {
    overall: overallEngagementRate
  };
};

const calculateEstimatedViews = (selectedContent, averages) => {
  if (!Array.isArray(selectedContent) || selectedContent.length === 0 || !averages) {
    return { min: 0, max: 0, expected: 0 };
  }

  let totalEstimatedViews = 0;

  selectedContent.forEach(content => {
    // Default to global average if specific type average is not available
    let baseViews = averages.globalAverageViews;

    // Try to use specific content type average if available
    switch (content.name) {
      case 'Instagram Reel':
        baseViews = averages.reelViews || averages.globalAverageViews;
        break;
      case 'Instagram Carousel':
        baseViews = averages.carouselViews || averages.globalAverageViews;
        break;
      case 'Instagram Post':  // Single photo post
        baseViews = averages.singlePhotoViews || averages.globalAverageViews;
        break;
      case 'Instagram Story':
        // Stories typically get 15-17% of regular post views
        baseViews = averages.globalAverageViews * 0.15;
        break;
      case 'TikTok Video':
        // TikTok videos typically get 50-70% of Instagram Reel views
        baseViews = averages.reelViews * 0.6 || averages.globalAverageViews * 0.6;
        break;
      case 'YouTube Video':
        // YouTube videos typically get 20-30% of Instagram Reel views
        baseViews = averages.reelViews * 0.25 || averages.globalAverageViews * 0.25;
        break;
      default:
        baseViews = averages.globalAverageViews;
        break;
    }

    // Calculate total views with quantity
    const quantity = content.quantity || 1;
    totalEstimatedViews += (baseViews * quantity);
  });

  // Ensure we always return positive numbers
  const expected = Math.max(0, Math.round(totalEstimatedViews));
  return {
    min: Math.max(0, Math.round(expected * 0.9)),
    max: Math.max(0, Math.round(expected * 1.1)),
    expected
  };
};

// CPM (Cost Per Mille) ranges for different content types
const CPM_RANGES = {
  reel: { min: 30, max: 50 },
  carousel: { min: 25, max: 40 },
  post: { min: 20, max: 35 },
  story: { min: 30, max: 40 }, // Updated CPM for stories
  default: { min: 20, max: 35 }
};

// Premium percentage for high engagement
const HIGH_ENGAGEMENT_PREMIUM = 0.5; // 50% premium
const HIGH_ENGAGEMENT_THRESHOLD = 0.02; // 2%

/**
 * Calculate engagement rate based on engagement metrics and views
 * @param {Object} metrics - Object containing likes, comments, shares, and views
 * @returns {number} Engagement rate as a decimal
 */
const calculateEngagementRateFromMetrics = (metrics) => {
  const { likes = 0, comments = 0, shares = 0, views = 0 } = metrics;
  if (!views) return 0;
  return (likes + comments + shares) / views;
};

/**
 * Calculate price range for a specific content type
 * @param {string} contentType - Type of content (reel, carousel, post, story)
 * @param {number} averageViews - Average number of views
 * @param {number} engagementRate - Engagement rate as a decimal
 * @returns {Object} Price range with min and max values
 */
const calculateContentTypePrice = (contentType, averageViews, engagementRate) => {
  // Get CPM range for content type
  const cpmRange = CPM_RANGES[contentType.toLowerCase()] || CPM_RANGES.default;
  
  // Calculate base price range per thousand views
  const baseMinPrice = (averageViews / 1000) * cpmRange.min;
  const baseMaxPrice = (averageViews / 1000) * cpmRange.max;
  
  // Apply premium if engagement rate is above threshold
  const premium = engagementRate > HIGH_ENGAGEMENT_THRESHOLD ? HIGH_ENGAGEMENT_PREMIUM : 0;
  
  // Calculate final prices with premium
  const minPrice = Math.max(50, Math.round(baseMinPrice * (1 + premium)));
  const maxPrice = Math.max(75, Math.round(baseMaxPrice * (1 + premium)));
  
  return {
    min: minPrice,
    max: maxPrice
  };
};

const calculateSuggestedRates = (
  globalAverageViews,
  reelViews,
  carouselViews,
  singlePhotoViews,
  followerCount,
  posts = []
) => {
  // Calculate average engagement metrics for different content types
  const metrics = calculateAverages(posts);
  
  // Calculate engagement rates for different content types
  const reelEngagement = calculateEngagementRateFromMetrics({
    ...metrics.reels,
    views: reelViews || globalAverageViews
  });
  
  const carouselEngagement = calculateEngagementRateFromMetrics({
    ...metrics.carousels,
    views: carouselViews || globalAverageViews
  });
  
  const postEngagement = calculateEngagementRateFromMetrics({
    ...metrics.posts,
    views: singlePhotoViews || globalAverageViews
  });
  
  // Calculate average views across all content types
  const averageViews = Math.max(
    globalAverageViews,
    (reelViews + carouselViews + singlePhotoViews) / 3
  );
  
  // Calculate story views as 15-17% of average post views
  const viewPercentage = 0.15 + (Math.random() * 0.02);
  const storyViews = Math.round(averageViews * viewPercentage);
  
  // Use global engagement rate for stories, with a minimum of 2%
  const storyEngagement = Math.max(metrics.globalEngagementRate || 0.02, 0.02);
  
  // Calculate prices for each content type
  return {
    'Instagram Reel': calculateContentTypePrice('reel', reelViews || globalAverageViews, reelEngagement),
    'Instagram Carousel': calculateContentTypePrice('carousel', carouselViews || globalAverageViews, carouselEngagement),
    'Instagram Post': calculateContentTypePrice('post', singlePhotoViews || globalAverageViews, postEngagement),
    'Instagram Story': calculateContentTypePrice('story', storyViews, storyEngagement)
  };
};

/**
 * Calculate price for a specific content type based on views and engagement
 * @param {string} platform - Platform (instagram, tiktok, youtube)
 * @param {string} contentType - Type of content (post, story, carousel, reel)
 * @param {number} followerCount - Number of followers
 * @param {number} engagementRate - Engagement rate as a decimal
 * @returns {Object} Price range with min and max values
 */
const calculatePricing = (platform, contentType, followerCount, engagementRate) => {
  // For backwards compatibility, ensure we're using the right content type
  const normalizedContentType = contentType.toLowerCase();
  let effectiveContentType = normalizedContentType;
  
  // Map video to reel for Instagram
  if (platform.toLowerCase() === 'instagram' && normalizedContentType === 'video') {
    effectiveContentType = 'reel';
  }
  
  // Use average views based on follower count if not provided
  const estimatedViews = followerCount * (engagementRate || 0.03);
  
  return calculateContentTypePrice(effectiveContentType, estimatedViews, engagementRate);
};

// Cache for Instagram data
let instagramDataCache = {
  data: null,
  timestamp: null,
  CACHE_DURATION: 5 * 60 * 1000 // 5 minutes
};

/**
 * Fetches Instagram data with caching
 * @param {string} username - Instagram username
 * @param {Object} options - Options containing Firebase functions
 * @param {boolean} bypassCache - Whether to bypass cache and fetch fresh data
 * @returns {Promise<Object>} Instagram data
 */
const fetchInstagramDataWithCache = async (username, { functions, fetchInstagramDataFunction, fetchRecentPostsFunction }, bypassCache = false) => {
  const now = Date.now();

  // Check if we have valid cached data (unless bypass is requested)
  if (!bypassCache && 
      instagramDataCache.data && 
      instagramDataCache.timestamp && 
      (now - instagramDataCache.timestamp) < instagramDataCache.CACHE_DURATION) {
    return instagramDataCache.data;
  }

  try {
    // Fetch both data in parallel using the provided function instances
    const [followerResult, postsResult] = await Promise.all([
      fetchInstagramDataFunction({ username }),
      fetchRecentPostsFunction({ username })
    ]);

    // Handle potential errors
    if (followerResult.data.error) {
      console.warn('Warning fetching follower count:', followerResult.data.error);
    }
    if (postsResult.data.error) {
      console.warn('Warning fetching posts:', postsResult.data.error);
    }

    const followerCount = followerResult.data.followers || 0;
    const posts = postsResult.data.posts || [];

    // Process the data
    const processedPosts = posts.map(post => ({
      ...post,
      estimatedViews: post.mediaType === 2 ? (post.playCount || 0) : Math.round((post.likeCount || 0) / 0.03)
    }));

    const calculatedAverages = calculateAverages(processedPosts);
    const suggestedRates = calculateSuggestedRates(
      calculatedAverages.globalAverageViews,
      calculatedAverages.reelViews,
      calculatedAverages.carouselViews,
      calculatedAverages.singlePhotoViews,
      followerCount,
      processedPosts
    );

    const result = {
      followerCount: followerCount,
      posts: processedPosts,
      averages: {
        globalAverageViews: calculatedAverages.globalAverageViews,
        reelViews: calculatedAverages.reelViews,
        carouselViews: calculatedAverages.carouselViews,
        singlePhotoViews: calculatedAverages.singlePhotoViews
      },
      engagement: calculatedAverages.engagement,
      suggestedRates
    };

    // Update cache only if not bypassing
    if (!bypassCache) {
      instagramDataCache = {
        data: result,
        timestamp: now,
        CACHE_DURATION: instagramDataCache.CACHE_DURATION
      };
    }

    return result;
  } catch (error) {
    console.error('Error fetching Instagram data:', error);
    throw error;
  }
};

async function fetchData(username, { functions, fetchInstagramDataFunction, fetchRecentPostsFunction }, bypassCache = false) {
  try {
    // Get follower count
    const followerResult = await fetchInstagramDataFunction({
      username: username.toLowerCase()
    });

    if (followerResult.data.error) {
      throw new Error(followerResult.data.error);
    }

    // Get recent posts
    const postsResult = await fetchRecentPostsFunction({
      username: username.toLowerCase()
    });

    if (postsResult.data.error) {
      throw new Error(postsResult.data.error);
    }

    // Process posts to add estimated views
    const processedPosts = postsResult.data.posts.map(post => ({
      ...post,
      estimatedViews: post.mediaType === 2 ? post.playCount : Math.round(post.likeCount / 0.03),
    }));

    const calculatedAverages = calculateAverages(processedPosts);
    const suggestedRates = calculateSuggestedRates(
      calculatedAverages.globalAverageViews,
      calculatedAverages.reelViews,
      calculatedAverages.carouselViews,
      calculatedAverages.singlePhotoViews,
      followerResult.data.followers,
      processedPosts
    );

    const result = {
      followerCount: followerResult.data.followers,
      posts: processedPosts,
      averages: {
        globalAverageViews: calculatedAverages.globalAverageViews,
        reelViews: calculatedAverages.reelViews,
        carouselViews: calculatedAverages.carouselViews,
        singlePhotoViews: calculatedAverages.singlePhotoViews
      },
      engagement: calculatedAverages.engagement,
      suggestedRates
    };

    // Update cache only if not bypassing
    if (!bypassCache) {
      instagramDataCache = {
        data: result,
        timestamp: Date.now()
      };
    }

    return result;
  } catch (error) {
    console.error('Error fetching Instagram data:', error);
    throw error;
  }
}

// Export all necessary functions
export {
  calculateAverages,
  calculateEngagementRate,
  calculateEstimatedViews,
  calculateSuggestedRates,
  calculatePricing,
  fetchInstagramDataWithCache,
  calculateStoryViews,
  fetchData
};