import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot, updateDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';
import './FeedbackDashboard.css';

const PRIORITY_COLORS = {
  high: '#ef4444',
  medium: '#f59e0b',
  low: '#10b981'
};

const STATUS_COLORS = {
  new: '#3b82f6',
  inProgress: '#8b5cf6',
  completed: '#10b981',
  declined: '#6b7280'
};

const FeedbackDashboard = () => {
  const [feedback, setFeedback] = useState([]);
  const [filter, setFilter] = useState('all');
  const [sort, setSort] = useState('newest');

  useEffect(() => {
    const q = query(collection(db, 'feedback'), orderBy('createdAt', 'desc'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const feedbackData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate()
      }));
      setFeedback(feedbackData);
    });

    return () => unsubscribe();
  }, []);

  const handleUpdateStatus = async (feedbackId, newStatus) => {
    try {
      await updateDoc(doc(db, 'feedback', feedbackId), {
        status: newStatus
      });
    } catch (error) {
      console.error('Error updating feedback status:', error);
    }
  };

  const handleUpdatePriority = async (feedbackId, newPriority) => {
    try {
      await updateDoc(doc(db, 'feedback', feedbackId), {
        priority: newPriority
      });
    } catch (error) {
      console.error('Error updating feedback priority:', error);
    }
  };

  const filteredFeedback = feedback.filter(item => {
    if (filter === 'all') return true;
    return item.type === filter;
  });

  const sortedFeedback = [...filteredFeedback].sort((a, b) => {
    if (sort === 'newest') return b.createdAt - a.createdAt;
    if (sort === 'oldest') return a.createdAt - b.createdAt;
    if (sort === 'priority') {
      const priorityOrder = { high: 3, medium: 2, low: 1 };
      return priorityOrder[b.priority] - priorityOrder[a.priority];
    }
    return 0;
  });

  const getFeedbackStats = () => {
    const stats = {
      total: feedback.length,
      featureRequests: feedback.filter(f => f.type === 'Feature Request').length,
      bugReports: feedback.filter(f => f.type === 'Bug Report').length,
      new: feedback.filter(f => f.status === 'new').length
    };
    return stats;
  };

  const stats = getFeedbackStats();

  return (
    <div className="feedback-dashboard">
      <div className="dashboard-header">
        <h1>Feedback Dashboard</h1>
        <div className="stats-grid">
          <div className="stat-card">
            <h3>Total Feedback</h3>
            <div className="stat-number">{stats.total}</div>
          </div>
          <div className="stat-card">
            <h3>Feature Requests</h3>
            <div className="stat-number">{stats.featureRequests}</div>
          </div>
          <div className="stat-card">
            <h3>Bug Reports</h3>
            <div className="stat-number">{stats.bugReports}</div>
          </div>
          <div className="stat-card">
            <h3>New</h3>
            <div className="stat-number">{stats.new}</div>
          </div>
        </div>
      </div>

      <div className="controls">
        <div className="filters">
          <select value={filter} onChange={(e) => setFilter(e.target.value)}>
            <option value="all">All Types</option>
            <option value="Feature Request">Feature Requests</option>
            <option value="Bug Report">Bug Reports</option>
            <option value="General Feedback">General Feedback</option>
            <option value="Suggestion">Suggestions</option>
          </select>
          <select value={sort} onChange={(e) => setSort(e.target.value)}>
            <option value="newest">Newest First</option>
            <option value="oldest">Oldest First</option>
            <option value="priority">By Priority</option>
          </select>
        </div>
      </div>

      <div className="feedback-list">
        {sortedFeedback.map(item => (
          <div key={item.id} className="feedback-card">
            <div className="feedback-header">
              <div className="feedback-type" style={{ backgroundColor: item.type === 'Bug Report' ? '#fee2e2' : '#e0e7ff' }}>
                {item.type}
              </div>
              <div className="feedback-date">
                {item.createdAt?.toLocaleDateString()}
              </div>
            </div>
            <div className="feedback-content">
              <p>{item.message}</p>
            </div>
            <div className="feedback-meta">
              <div className="user-info">
                <span className="user-email">{item.userEmail}</span>
                <span className="platform">{item.platform}</span>
              </div>
              <div className="feedback-actions">
                <select 
                  value={item.priority} 
                  onChange={(e) => handleUpdatePriority(item.id, e.target.value)}
                  style={{ color: PRIORITY_COLORS[item.priority] }}
                >
                  <option value="high">High Priority</option>
                  <option value="medium">Medium Priority</option>
                  <option value="low">Low Priority</option>
                </select>
                <select 
                  value={item.status} 
                  onChange={(e) => handleUpdateStatus(item.id, e.target.value)}
                  style={{ color: STATUS_COLORS[item.status] }}
                >
                  <option value="new">New</option>
                  <option value="inProgress">In Progress</option>
                  <option value="completed">Completed</option>
                  <option value="declined">Declined</option>
                </select>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeedbackDashboard;
