import React, { useState } from 'react';
import './FAQ.css';

const FAQ = ({ onBack }) => {
  const [expandedQuestion, setExpandedQuestion] = useState(null);

  const handleQuestionClick = (sectionIndex, questionIndex) => {
    const questionId = `${sectionIndex}-${questionIndex}`;
    setExpandedQuestion(expandedQuestion === questionId ? null : questionId);
  };

  const faqSections = [
    {
      title: "General Questions",
      questions: [
        {
          question: "What is Kaiju?",
          answer: "Kaiju is a platform that helps content creators monetize their social media presence by connecting them with people who want to purchase their services, such as sponsored content, shoutouts, or custom creative work."
        },
        {
          question: "How does Kaiju work?",
          answer: "Creators set up their profile, list their services with prices, and share their unique Kaiju link. Buyers can then browse services, make purchases, and communicate directly with creators. All payments are handled securely through our platform."
        },
        {
          question: "Which social media platforms does Kaiju support?",
          answer: <>
            Kaiju currently supports multiple platforms including:
            <ul>
              <li>Instagram</li>
              <li>TikTok</li>
              <li>YouTube</li>
              <li>Twitch</li>
              <li>Twitter/X</li>
              <li>LinkedIn</li>
            </ul>
            We're constantly expanding! More platforms will be added based on creator demand and market trends.
          </>
        }
      ]
    },
    {
      title: "For Creators",
      questions: [
        {
          question: "Is Kaiju free to join?",
          answer: "Yes, Kaiju is free to join for creators. We only take a small platform fee when you make a sale."
        },
        {
          question: "How do I get started as a creator?",
          answer: <>
            <ul>
              <li>Sign up for a free account</li>
              <li>Claim your unique username</li>
              <li>Connect your social media accounts</li>
              <li>Set up your services and prices</li>
              <li>Complete your payment information</li>
              <li>Share your Kaiju link with your audience</li>
            </ul>
          </>
        },
        {
          question: "How do I get paid?",
          answer: "Payments are processed securely through Stripe. Once you complete the Stripe verification process, payments will be automatically transferred to your bank account after each successful transaction."
        }
      ]
    },
    {
      title: "For Buyers",
      questions: [
        {
          question: "Who can purchase creator services?",
          answer: <>
            Anyone can purchase services through Kaiju! Whether you're:
            <ul>
              <li>A brand looking for influencer marketing</li>
              <li>A creator wanting to be featured by other creators</li>
              <li>A business seeking social media exposure</li>
              <li>An individual interested in custom content</li>
            </ul>
          </>
        },
        {
          question: "How do I purchase creator services?",
          answer: <>
            <ul>
              <li>Visit a creator's Kaiju profile</li>
              <li>Select the service(s) you're interested in</li>
              <li>Submit your requirements</li>
              <li>Complete the secure payment process</li>
              <li>Wait for the creator to accept and fulfill your order</li>
            </ul>
          </>
        },
        {
          question: "What payment methods do you accept?",
          answer: "We currently accept all major credit and debit cards through our secure payment processor, Stripe. We're excited to announce that additional payment methods are coming soon, including PayPal and cryptocurrencies!"
        },
        {
          question: "What happens after I make a purchase?",
          answer: <>
            <ul>
              <li>The creator receives your order details and requirements</li>
              <li>They can accept or decline the request</li>
              <li>Once accepted, you'll be charged and the creator will begin working on your content</li>
              <li>You'll receive updates and the final content through our platform</li>
            </ul>
          </>
        },
        {
          question: "Is my payment secure?",
          answer: "Yes, all payments are processed through Stripe, a leading payment processor that maintains the highest security standards and PCI compliance."
        },
        {
          question: "What if I'm not satisfied with the content?",
          answer: "We recommend clearly communicating your requirements upfront. If there are issues with the delivered content, you can work with the creator to request revisions based on their stated terms."
        }
      ]
    },
    {
      title: "Technical Questions",
      questions: [
        {
          question: "Do I need to connect my social media accounts?",
          answer: "No, you don't need to connect your social media accounts to use Kaiju. Simply provide your social media handles, and we'll do the rest!"
        },
        {
          question: "Is my data secure?",
          answer: "Yes, we take data security seriously. We use industry-standard encryption and security practices to protect your personal and payment information."
        },
        {
          question: "What happens if I lose my account access?",
          answer: "You can recover your account through our secure password reset process. If you need additional help, our support team is available to assist with account recovery."
        }
      ]
    },
    {
      title: "Support",
      questions: [
        {
          question: "How can I get help if I have issues?",
          answer: "You can reach our support team at hi@kaiju.bio. We aim to respond to all inquiries within 24 hours."
        },
        {
          question: "Do you offer refunds?",
          answer: "Refund policies are set by individual creators and should be clearly stated in their service terms. Any refund requests should be discussed directly with the creator through our platform."
        },
        {
          question: "Where can I report issues?",
          answer: "You can report technical issues or concerns through our platform's reporting system or by contacting our support team directly at hi@kaiju.bio."
        }
      ]
    }
  ];

  return (
    <div className="faq-container">
      <div className="faq-header">
        <button className="back-button" onClick={onBack}>
          <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none">
            <path d="M19 12H5M12 19l-7-7 7-7"/>
          </svg>
          Back to Settings
        </button>
      </div>

      <div className="faq-content">
        {faqSections.map((section, sectionIndex) => (
          <div key={sectionIndex} className="faq-section">
            <h2>{section.title}</h2>
            {section.questions.map((item, itemIndex) => (
              <div key={itemIndex} className="faq-item">
                <button
                  className={`faq-question ${expandedQuestion === `${sectionIndex}-${itemIndex}` ? 'expanded' : ''}`}
                  onClick={() => handleQuestionClick(sectionIndex, itemIndex)}
                >
                  {item.question}
                  <span className="faq-arrow" />
                </button>
                {expandedQuestion === `${sectionIndex}-${itemIndex}` && (
                  <div className="faq-answer">
                    {item.answer}
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}

        <div className="faq-footer">
          <p>Have more questions? Contact us at <a href="mailto:hi@kaiju.bio">hi@kaiju.bio</a></p>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
