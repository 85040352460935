import React from 'react';
import './FirstOfferCelebration.css';

const FirstOfferCelebration = ({ onClose }) => {
  const handleOverlayClick = (e) => {
    // Only close if clicking the overlay itself, not the modal content
    if (e.target.className === 'celebration-overlay') {
      onClose();
    }
  };

  return (
    <div className="celebration-overlay" onClick={handleOverlayClick}>
      <div className="celebration-modal">
        <div className="celebration-content">
          <div className="celebration-emoji">🎉</div>
          <h2>Congratulations!</h2>
          <p>You've just created your first offer!</p>
          <p className="celebration-subtitle">Your creator journey begins now. Share your profile link to start receiving orders.</p>
        </div>
      </div>
    </div>
  );
};

export default FirstOfferCelebration;
