import React, { useState } from 'react';
import SellerConnectButton from './SellerConnectButton';
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import './PaymentMethodsSection.css';

function PaymentMethodsSection({ 
  sellerId, 
  stripeOnboardingComplete, 
  stripeAccountId 
}) {
  const [showFeedback, setShowFeedback] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState('');
  const [otherMethod, setOtherMethod] = useState('');
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [showOtherInput, setShowOtherInput] = useState(false);

  const MAX_LENGTH = 50;

  const handleOtherMethodChange = (e) => {
    const value = e.target.value;
    // Only allow letters, numbers, spaces, and common symbols
    const sanitized = value.replace(/[^a-zA-Z0-9\s\-_&@.,]/g, '');
    if (sanitized.length <= MAX_LENGTH) {
      setOtherMethod(sanitized);
    }
  };

  const handleFeedbackSubmit = async (method) => {
    try {
      if (method === 'other' && !otherMethod.trim()) {
        setShowOtherInput(true);
        return;
      }

      const db = getFirestore();
      await addDoc(collection(db, 'feedback'), {
        type: 'payment_method_vote',
        method: method === 'other' ? otherMethod.trim() : method,
        sellerId,
        timestamp: serverTimestamp(),
        source: 'payment_methods_page',
      });

      setSelectedMethod(method === 'other' ? otherMethod : method);
      setFeedbackSubmitted(true);
      setShowOtherInput(false);
      setOtherMethod('');
      
      // Reset after 3 seconds
      setTimeout(() => {
        setFeedbackSubmitted(false);
        setShowFeedback(false);
      }, 3000);
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  return (
    <div className="payment-methods-section">
      <div className="section-header">
        <h2 className="payment-methods-title">Choose How You Want to Get Paid 💸</h2>
        <p className="payment-methods-subtitle">Select from our secure payment options to start receiving payments from your customers</p>
      </div>
      
      <div className="payment-methods-grid">
        {/* Stripe Section */}
        <div className="payment-method-card active">
          <div className="payment-method-header">
            <div className="payment-method-name">
              <span className="payment-icon">💳</span> Stripe
            </div>
            <div className={`payment-method-status ${stripeOnboardingComplete ? 'connected' : 'not-connected'}`}>
              {stripeOnboardingComplete ? '✓ Connected' : 'Not Connected'}
            </div>
          </div>
          <p className="payment-method-description">
            Accept credit cards, Apple Pay, Google Pay, and more. Get paid securely with instant setup and competitive rates.
          </p>
          <SellerConnectButton 
            sellerId={sellerId}
            stripeOnboardingComplete={stripeOnboardingComplete}
            stripeAccountId={stripeAccountId}
          />
        </div>

        {/* PayPal Section */}
        <div className="payment-method-card coming-soon">
          <div className="payment-method-header">
            <div className="payment-method-name">
              <span className="payment-icon">🌐</span> PayPal
            </div>
            <div className="payment-method-status coming-soon-badge">
              Coming Soon
            </div>
          </div>
          <p className="payment-method-description">
            Accept PayPal payments and tap into 400+ million active PayPal users worldwide with buyer protection included.
          </p>
          <button className="payment-method-button" disabled>
            Connect PayPal
          </button>
        </div>

        {/* Crypto Section */}
        <div className="payment-method-card coming-soon">
          <div className="payment-method-header">
            <div className="payment-method-name">
              <span className="payment-icon">₿</span> Cryptocurrency
            </div>
            <div className="payment-method-status coming-soon-badge">
              Coming Soon
            </div>
          </div>
          <p className="payment-method-description">
            Accept Bitcoin, Ethereum, and other major cryptocurrencies with instant settlements and global accessibility.
          </p>
          <button className="payment-method-button" disabled>
            Setup Crypto
          </button>
        </div>
      </div>

      {/* Feedback Section */}
      <div className="feedback-section">
        {!showFeedback && !feedbackSubmitted && (
          <button 
            className="feedback-button"
            onClick={() => {
              setShowFeedback(true);
              setShowOtherInput(false);
              setOtherMethod('');
            }}
          >
            🗳️ How do you want to get paid? Vote for your favorite Payment Method
          </button>
        )}

        {showFeedback && !feedbackSubmitted && (
          <div className="feedback-container">
            <p>Which payment method would you like us to add next?</p>
            <div className="feedback-options">
              <button 
                className="feedback-option-button"
                onClick={() => handleFeedbackSubmit('paypal')}
              >
                PayPal 🌐
              </button>
              <button 
                className="feedback-option-button"
                onClick={() => handleFeedbackSubmit('crypto')}
              >
                Cryptocurrency ₿
              </button>
              <button 
                className="feedback-option-button"
                onClick={() => handleFeedbackSubmit('bank')}
              >
                Bank Transfer 🏦
              </button>
              <button 
                className="feedback-option-button"
                onClick={() => handleFeedbackSubmit('other')}
              >
                Other 💡
              </button>
            </div>
            {showOtherInput && (
              <div className="other-input-container">
                <div className="input-wrapper">
                  <input
                    type="text"
                    value={otherMethod}
                    onChange={handleOtherMethodChange}
                    placeholder="What payment method would you like?"
                    className="other-input"
                    maxLength={MAX_LENGTH}
                  />
                  <span className="char-count">
                    {otherMethod.length}/{MAX_LENGTH}
                  </span>
                </div>
                <button 
                  className="submit-other-button"
                  onClick={() => handleFeedbackSubmit('other')}
                  disabled={!otherMethod.trim()}
                >
                  Submit
                </button>
              </div>
            )}
          </div>
        )}

        {feedbackSubmitted && (
          <div className="feedback-success">
            Thanks for your vote! We'll work on adding {selectedMethod} support soon! 🚀
          </div>
        )}
      </div>
    </div>
  );
}

export default PaymentMethodsSection;
