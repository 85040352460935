// PaymentSuccess.js

import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';
import { useNavigate, useLocation } from 'react-router-dom';
import './PaymentSuccess.css';

function PaymentSuccess() {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const [paymentStatus, setPaymentStatus] = useState('success'); // default to success

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);

    // Check for payment status in query params
    const urlParams = new URLSearchParams(location.search);
    const status = urlParams.get('status');
    if (status === 'failed') {
      setPaymentStatus('failed');
    } else {
      setPaymentStatus('success');
    }

    return () => window.removeEventListener('resize', handleResize);
  }, [location]);

  const handleBackToHome = () => {
    navigate('/');
  };

  return (
    <div className="payment-success-container">
      {paymentStatus === 'success' ? (
        <>
          <Confetti
            width={windowDimensions.width}
            height={windowDimensions.height}
          />
          <div className="payment-success-content">
            <h1 className="payment-success-title">🎉 Congratulations! 🎉</h1>
            <p className="payment-success-subtitle">
              Your payment was successful.
            </p>
            <p>A confirmation email has been sent to your email address.</p>
            <button
              onClick={handleBackToHome}
              className="payment-success-button"
            >
              Back to Home
            </button>
          </div>
        </>
      ) : (
        <div className="payment-failed-content">
          <h1 className="payment-failed-title">Payment Failed</h1>
          <p className="payment-failed-subtitle">
            Unfortunately, your payment could not be processed.
          </p>
          <p>Please try again or contact support if the issue persists.</p>
          <button
            onClick={handleBackToHome}
            className="payment-failed-button"
          >
            Back to Home
          </button>
        </div>
      )}
    </div>
  );
}

export default PaymentSuccess;
