import React from 'react';
import { useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti';
import './SuccessPage.css';

function RequestAcceptedPage() {
  const navigate = useNavigate();

  return (
    <div className="success-page-container">
      <Confetti />
      <div className="success-page-content">
        <h1 className="success-page-title">Order Accepted!</h1>
        <p className="success-page-subtitle">You've successfully accepted the order.</p>
        <button onClick={() => navigate('/dashboard')} className="success-page-button">Back to Dashboard</button>
      </div>
    </div>
  );
}

export default RequestAcceptedPage;