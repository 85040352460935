import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import './AddSocialMedia.css';

// Import logo images
import twitterLogo from '../assets/twitter.png';
import instagramLogo from '../assets/instagram.png';
import linkedinLogo from '../assets/linkedin.png';
import youtubeLogo from '../assets/youtube.png';
import twitchLogo from '../assets/twitch.png';
import tiktokLogo from '../assets/tiktok.png';

const socialMediaPlatforms = [
  { name: 'Instagram', logo: instagramLogo, color: '#C13584' },
  { name: 'TikTok', logo: tiktokLogo, color: '#000000' },
  { name: 'YouTube', logo: youtubeLogo, color: '#FF0000' },
  { name: 'Twitch', logo: twitchLogo, color: '#6441A4' },
  { name: 'Twitter', logo: twitterLogo, color: '#1DA1F2' },
  { name: 'LinkedIn', logo: linkedinLogo, color: '#0077B5' },
];

function AddSocialMedia() {
  const [accounts, setAccounts] = useState({});
  const [tempAccounts, setTempAccounts] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserAccounts = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setAccounts(userData.socialMedia || {});
          
          const storedTempAccounts = JSON.parse(localStorage.getItem('tempSocialMediaAccounts')) || {};
          setTempAccounts({...userData.socialMedia, ...storedTempAccounts});
        }
      }
    };
    fetchUserAccounts();
  }, []);

  useEffect(() => {
    localStorage.setItem('tempSocialMediaAccounts', JSON.stringify(tempAccounts));
  }, [tempAccounts]);

  const handleInputChange = (platform, value) => {
    setTempAccounts(prev => ({
      ...prev,
      [platform.toLowerCase()]: { handle: value }
    }));
  };

  const handleAddAccount = async (platform) => {
    if (tempAccounts[platform.toLowerCase()]?.handle) {
      const user = auth.currentUser;
      if (user) {
        try {
          const newAccounts = {
            ...accounts,
            [platform.toLowerCase()]: tempAccounts[platform.toLowerCase()]
          };
          await updateDoc(doc(db, 'users', user.uid), {
            socialMedia: newAccounts
          });
          setAccounts(newAccounts);
        } catch (error) {
          console.error('Error adding account:', error);
        }
      }
    }
  };

  const handleRemove = async (platform) => {
    const user = auth.currentUser;
    if (user) {
      try {
        const newAccounts = { ...accounts };
        delete newAccounts[platform.toLowerCase()];
        await updateDoc(doc(db, 'users', user.uid), {
          socialMedia: newAccounts
        });
        setAccounts(newAccounts);
        setTempAccounts(prev => {
          const newTemp = { ...prev };
          delete newTemp[platform.toLowerCase()];
          return newTemp;
        });
      } catch (error) {
        console.error('Error removing account:', error);
      }
    }
  };

  const handleContinue = () => {
    localStorage.removeItem('tempSocialMediaAccounts');
    const isOnboarding = localStorage.getItem('isOnboarding');
    if (isOnboarding) {
      localStorage.removeItem('isOnboarding');
      navigate('/finish-profile');
    } else {
      navigate('/dashboard');
    }
  };

  return (
    <div className="kaiju-social-container">
      <button onClick={() => navigate(-1)} className="kaiju-social-back-button">&lt; back</button>
      <h1 className="kaiju-social-title">Add your social media accounts</h1>
      
      <div className="kaiju-social-form">
        {socialMediaPlatforms.map((platform) => (
          <div key={platform.name} className="kaiju-social-input">
            <img src={platform.logo} alt={platform.name} className="kaiju-social-logo" />
            <div 
              className={`kaiju-social-input-wrapper ${accounts[platform.name.toLowerCase()]?.handle ? 'added' : ''}`}
              style={{ backgroundColor: accounts[platform.name.toLowerCase()]?.handle ? platform.color : '#f0f0f0' }}
            >
              {accounts[platform.name.toLowerCase()]?.handle && (
                <span className="kaiju-social-checkmark">✓</span>
              )}
              <span className={`kaiju-social-at ${accounts[platform.name.toLowerCase()]?.handle ? 'added' : ''}`}>@</span>
              <input
                type="text"
                placeholder="username"
                value={tempAccounts[platform.name.toLowerCase()]?.handle || ''}
                onChange={(e) => handleInputChange(platform.name, e.target.value)}
                readOnly={!!accounts[platform.name.toLowerCase()]?.handle}
              />
              {accounts[platform.name.toLowerCase()]?.handle && (
                <button
                  className="kaiju-social-remove-button"
                  onClick={() => handleRemove(platform.name)}
                >
                  ×
                </button>
              )}
            </div>
            {!accounts[platform.name.toLowerCase()]?.handle && tempAccounts[platform.name.toLowerCase()]?.handle && (
              <button
                className="kaiju-social-add-button"
                onClick={() => handleAddAccount(platform.name)}
              >
                Add
              </button>
            )}
          </div>
        ))}
      </div>
      
      <button className="continue-button" onClick={handleContinue}>Continue</button>
    </div>
  );
}

export default AddSocialMedia;