// src/components/ProgressBar.js

import React from 'react';
import './ProgressBar.css';

// Helper function to format numbers to "K", "M", or "B" notation
const formatNumber = (num) => {
    if (num >= 1e12) { // 1,000,000,000,000
      return `${(num / 1e12).toFixed(1)}T`;
    } else if (num >= 1e9) { // 1,000,000,000
      return `${(num / 1e9).toFixed(1)}B`;
    } else if (num >= 1e6) { // 1,000,000
      return `${(num / 1e6).toFixed(1)}M`;
    } else if (num >= 1e3) { // 1,000
      return `${(num / 1e3).toFixed(1)}K`;
    }
    return num.toLocaleString();
  };
  

const ProgressBar = ({ estimatedReach, progressWidth }) => {
  return (
    <div className="progress-bar-container">
      <h2>Estimated Views for Your Order:</h2>
      <div className="reach-container">
        <span className="reach-label">Reach</span>
        <span className="reach-value">
          {formatNumber(estimatedReach.min)} - {formatNumber(estimatedReach.max)}
        </span>
      </div>
      <div className="progress-bar">
        <div className="progress" style={{ width: `${progressWidth}%` }}></div>
      </div>
      <p className="disclaimer">
        Estimated views are based on creator data. These are estimates, not guarantees. Actual views may differ.
      </p>
      <p className="platform-note">
        Currently showing estimates for Instagram content only. Estimates for other platforms coming soon!
      </p>
    </div>
  );
};

export default ProgressBar;
