import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import confetti from 'canvas-confetti';
import './OrdersList.css';
import LoadingOverlay from './LoadingOverlay';

const getDateInMillis = (order) => {
  if (order.orderDate?.toMillis) return order.orderDate.toMillis();
  if (order.orderDate?.seconds) return order.orderDate.seconds * 1000;
  if (order.createdAt?.toMillis) return order.createdAt.toMillis();
  if (order.createdAt?.seconds) return order.createdAt.seconds * 1000;
  if (order.timestamp?.toMillis) return order.timestamp.toMillis();
  if (order.timestamp?.seconds) return order.timestamp.seconds * 1000;
  return Date.now();
};

const OrdersList = ({ 
  orders, 
  onAcceptOrder, 
  onDeclineOrder,
  onDeclineClick,
  onDeclineSubmit,
  stripeOnboardingComplete,
  declineReason,
  setDeclineReason,
  showFaq,
  setShowFaq,
  onCompleteOrder,
  onSendMessage = () => {}
}) => {
  const [expandedOrderId, setExpandedOrderId] = useState(null);
  const [acceptingOrderId, setAcceptingOrderId] = useState(null);
  const [decliningOrderId, setDecliningOrderId] = useState(null);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [sortedOrders, setSortedOrders] = useState([]);
  const [showAcceptGif, setShowAcceptGif] = useState(false);
  const [showDeclineGif, setShowDeclineGif] = useState(false);
  const [showFeedback, setShowFeedback] = useState('');
  const [completingOrderId, setCompletingOrderId] = useState(null);
  const [showCompleteConfirm, setShowCompleteConfirm] = useState(false);
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);
  const [completionMessage, setCompletionMessage] = useState('');

  const triggerConfetti = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  };

  useEffect(() => {
    const newSortedOrders = [...orders].sort((a, b) => {
      const dateA = getDateInMillis(a);
      const dateB = getDateInMillis(b);
      return dateB - dateA;
    });
    setSortedOrders(newSortedOrders);
  }, [orders]);

  useEffect(() => {
    if (showFeedback) {
      const timer = setTimeout(() => {
        setShowFeedback('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showFeedback]);

  const toggleOrderExpansion = (orderId) => {
    setExpandedOrderId(expandedOrderId === orderId ? null : orderId);
  };

  const handleModalClose = () => {
    setShowAcceptModal(false);
    setShowDeclineModal(false);
    setShowCompleteConfirm(false);
    setSelectedOrderId(null);
    setDeclineReason('');
    setCompletionMessage('');
    setAcceptingOrderId(null);
    setDecliningOrderId(null);
    setCompletingOrderId(null);
  };

  const handleAcceptOrder = async (orderId) => {
    if (!stripeOnboardingComplete) {
      setSelectedOrderId(orderId);
      setShowAcceptModal(true);
      return;
    }

    if (acceptingOrderId) return;

    const originalOrder = orders.find(o => o.id === orderId);
    if (!originalOrder) return;

    try {
      setAcceptingOrderId(orderId);
      setShowLoadingOverlay(true);
      await onAcceptOrder(orderId);
      
      triggerConfetti();
      setShowAcceptGif(true);
      setTimeout(() => {
        setShowAcceptGif(false);
        setAcceptingOrderId(null);
        setShowLoadingOverlay(false);
      }, 3000);
    } catch (error) {
      console.error('Error accepting order:', error);
      setSortedOrders(prevOrders => 
        prevOrders.map(o => 
          o.id === orderId ? { ...o, status: originalOrder.status } : o
        )
      );
      setShowFeedback('Failed to accept order. Please try again.');
      setShowLoadingOverlay(false);
      setAcceptingOrderId(null);
    }
  };

  const handleDeclineOrder = async (orderId) => {
    if (decliningOrderId) return;
    setSelectedOrderId(orderId);
    setShowDeclineModal(true);
  };

  const handleDeclineSubmit = async () => {
    if (!selectedOrderId || !declineReason.trim()) {
      setShowFeedback("Please provide a reason for declining");
      return;
    }

    try {
      setDecliningOrderId(selectedOrderId);
      await onDeclineOrder(selectedOrderId, declineReason);
      setShowDeclineModal(false);
      setShowDeclineGif(true);
      
      setTimeout(() => {
        setShowDeclineGif(false);
        setDecliningOrderId(null);
        setSelectedOrderId(null);
        setDeclineReason('');
      }, 3000);
    } catch (error) {
      console.error('Error declining order:', error);
      setShowFeedback('Failed to decline order. Please try again.');
    }
  };

  const handleCompleteOrder = async (orderId) => {
    if (completingOrderId) return;

    try {
      setCompletingOrderId(orderId);
      setSelectedOrderId(orderId);
      setShowCompleteConfirm(true);
    } catch (error) {
      console.error('Error setting up order completion:', error);
      setShowFeedback('Failed to set up order completion. Please try again.');
      setCompletingOrderId(null);
    }
  };

  const confirmCompleteOrder = async () => {
    if (!completingOrderId) return;

    try {
      setShowCompleteConfirm(false);  // First hide the modal
      setShowLoadingOverlay(true);    // Then show loading
      
      await onCompleteOrder(completingOrderId, completionMessage);
      
      setShowLoadingOverlay(false);   // Hide loading
      setShowAcceptGif(true);         // Finally show success gif
      triggerConfetti();
      
      setTimeout(() => {
        setShowAcceptGif(false);
        setCompletingOrderId(null);
        setCompletionMessage('');
      }, 3000);
    } catch (error) {
      console.error('Error completing order:', error);
      setShowLoadingOverlay(false);
      setShowFeedback('Failed to complete order. Please try again.');
      setCompletingOrderId(null);
    }
  };

  const renderOrderActions = (order) => {
    if (order.status === 'pending') {
      return (
        <div className="action-buttons">
          <button
            className="action-button accept"
            onClick={() => handleAcceptOrder(order.id)}
            disabled={acceptingOrderId === order.id}
          >
            {acceptingOrderId === order.id ? '⏳ Accepting...' : 'Accept'}
          </button>
          <button
            className="action-button decline"
            onClick={() => handleDeclineOrder(order.id)}
            disabled={decliningOrderId === order.id}
          >
            {decliningOrderId === order.id ? '⏳ Declining...' : 'Decline'}
          </button>
        </div>
      );
    } else if (order.status === 'payment_received') {
      return (
        <button
          className="complete-button"
          onClick={() => handleCompleteOrder(order.id)}
          disabled={completingOrderId === order.id}
        >
          {completingOrderId === order.id ? '⏳ Completing...' : 'Mark as Completed'}
        </button>
      );
    } else {
      return (
        <div className={`status-badge ${order.status}`}>
          {order.status === 'accepted' && 'Accepted'}
          {order.status === 'completed' && 'Completed'}
          {order.status === 'declined' && 'Declined'}
          {order.status === 'cancelled' && 'Cancelled'}
          {!['accepted', 'completed', 'declined', 'cancelled'].includes(order.status) && 
            order.status.charAt(0).toUpperCase() + order.status.slice(1)}
        </div>
      );
    }
  };

  return (
    <div className="orders-container">
      {showAcceptGif && (
        <div className="gif-overlay">
          <div className="feedback-container">
            <img 
              src="https://media.giphy.com/media/OHs6JJvRA4v7hsxuzF/giphy.gif" 
              alt="Success" 
              className="success-gif" 
            />
            <div className="feedback-message success">
              Order Accepted Successfully!
            </div>
          </div>
        </div>
      )}

      {!stripeOnboardingComplete && sortedOrders.length > 0 && (
        <div className="payment-setup-banner">
          <div className="banner-content">
            <p>💰 How would you like to get paid? Set up your payment details to start receiving orders!</p>
            <a href="/dashboard?section=payments" className="setup-button">
              Set Up Payments
            </a>
          </div>
        </div>
      )}

      {sortedOrders.length === 0 ? (
        <div className="no-orders">
          <p>No orders yet</p>
        </div>
      ) : (
        <>
          <div className="order-header">
            <div>Client</div>
            <div>Amount</div>
            <div>Date</div>
            <div>Status</div>
          </div>
          <div className="orders-list">
            {showLoadingOverlay && (
              <LoadingOverlay 
                message={`Please wait while we accept this order and notify ${orders.find(o => o.id === (acceptingOrderId || selectedOrderId))?.buyerName || 'the buyer'}`}
              />
            )}
            {sortedOrders.map((order) => (
              <React.Fragment key={order.id}>
                <div 
                  className={`order-item ${expandedOrderId === order.id ? 'expanded' : ''}`}
                  onClick={() => toggleOrderExpansion(order.id)}
                >
                  <div className="order-cell" data-label="Client">
                    {order.buyerName || 'Anonymous'}
                  </div>
                  <div className="order-cell" data-label="Amount">
                    ${typeof order.totalAmount === 'number' 
                      ? order.totalAmount.toFixed(2) 
                      : (order.amount || 0).toFixed(2)}
                  </div>
                  <div className="order-cell" data-label="Date">
                    {format(new Date(getDateInMillis(order)), 'MMM d')}
                  </div>
                  <div className="order-cell" data-label="Status">
                    {renderOrderActions(order)}
                  </div>
                </div>
                {expandedOrderId === order.id && (
                  <div className="order-details">
                    <div className="order-info">
                      <div className="order-info-grid">
                        <div className="order-info-section">
                          <h3>Order Information</h3>
                          <div className="info-group">
                            <label>Order ID</label>
                            <div>{order.id}</div>
                          </div>
                          <div className="info-group">
                            <label>Client Name</label>
                            <div>{order.buyerName || 'Anonymous'}</div>
                          </div>
                          <div className="info-group">
                            <label>Order Date</label>
                            <div>{format(new Date(getDateInMillis(order)), 'MMMM d, yyyy')}</div>
                          </div>
                          <div className="info-group">
                            <label>Total Amount</label>
                            <div className="amount">
                              ${typeof order.totalAmount === 'number' 
                                ? order.totalAmount.toFixed(2) 
                                : (order.amount || 0).toFixed(2)}
                            </div>
                          </div>
                        </div>

                        <div className="order-info-section">
                          <h3>Products</h3>
                          <div className="products-grid">
                            {order.offers?.map((offer, index) => (
                              <div key={index} className="product-card">
                                <div className="product-name">{offer.name || offer.title}</div>
                                <div className="product-details">
                                  <span className="product-price">${offer.price?.toFixed(2) || '0.00'}</span>
                                  <span className="product-quantity">× {offer.quantity}</span>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>

                        <div className="order-info-section requirements-section">
                          <h3>Requirements</h3>
                          <div className="requirements-box">
                            {order.buyerRequirements || order.requirements || 'No specific requirements'}
                          </div>
                          <div className="message-action">
                            <button 
                              onClick={(e) => {
                                e.stopPropagation();
                                onSendMessage(order.id);
                              }}
                              className="action-button message"
                              disabled
                            >
                              Send Message (coming soon)
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </>
      )}

      {showFeedback && (
        <div className="feedback-message">
          {showFeedback}
        </div>
      )}

      {showAcceptModal && (
        <div className="modal-overlay" onClick={handleModalClose}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <h3>Accept Order</h3>
            {!stripeOnboardingComplete ? (
              <>
                <p>You need to set up your payment information before you can accept orders.</p>
                <div className="modal-actions">
                  <button 
                    onClick={handleModalClose}
                    className="cancel-button"
                  >
                    Cancel
                  </button>
                  <button 
                    onClick={() => window.location.href = '/dashboard?section=payments'}
                    className="setup-button"
                  >
                    Set Up Payments
                  </button>
                </div>
              </>
            ) : (
              <>
                <p>Are you sure you want to accept this order?</p>
                <div className="modal-actions">
                  <button 
                    onClick={() => handleAcceptOrder(selectedOrderId)}
                    className="action-button accept"
                    disabled={acceptingOrderId === selectedOrderId}
                  >
                    {acceptingOrderId === selectedOrderId ? '⏳ Processing...' : '✅ Yes, Accept'}
                  </button>
                  <button 
                    onClick={handleModalClose}
                    className="cancel-button"
                  >
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {showDeclineModal && (
        <div className="modal-overlay" onClick={handleModalClose}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <h3>Decline Order</h3>
            <p>Are you sure you want to decline this order?</p>
            <textarea
              value={declineReason}
              onChange={(e) => setDeclineReason(e.target.value)}
              placeholder="Optional: Provide a reason for declining"
              className="decline-reason-input"
            />
            <div className="modal-actions">
              <button 
                onClick={handleDeclineSubmit}
                className="action-button decline"
                disabled={!declineReason.trim()}
              >
                Decline Order
              </button>
              <button 
                onClick={handleModalClose}
                className="cancel-button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {showCompleteConfirm && (
        <div className="modal-overlay" onClick={handleModalClose}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <h3>Complete Order</h3>
            <p>
              Are you sure you want to mark this order as complete? 
              This action cannot be undone.
            </p>
            <div className="completion-message-container">
              <label htmlFor="completionMessage">Message to Buyer (Optional)</label>
              <textarea
                id="completionMessage"
                value={completionMessage}
                onChange={(e) => setCompletionMessage(e.target.value)}
                placeholder="Add any delivery links, instructions, or a thank you message for your buyer..."
                className="completion-message-input"
                rows={4}
              />
            </div>
            <div className="modal-actions">
              <button 
                onClick={confirmCompleteOrder}
                className="action-button accept"
              >
                Mark as Completed
              </button>
              <button 
                onClick={handleModalClose}
                className="cancel-button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {(showAcceptGif || showDeclineGif) && (
        <div className="gif-overlay">
          <div className="feedback-container">
            {showAcceptGif && (
              <>
                <img 
                  src="https://media.giphy.com/media/OHs6JJvRA4v7hsxuzF/giphy.gif" 
                  alt="Success" 
                  className="success-gif"
                />
                <div className="feedback-message success">
                  Order Accepted Successfully!
                </div>
              </>
            )}
            {showDeclineGif && (
              <>
                <img 
                  src="https://media.giphy.com/media/PsHrpsuiZLAEE/giphy.gif" 
                  alt="Decline Animation" 
                  className="decline-gif"
                />
                <div className="feedback-message decline">
                  Order Declined
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default OrdersList;