import React, { useState, useEffect } from 'react';
import { collection, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
import './PaymentVotesDashboard.css';

const PaymentVotesDashboard = () => {
  const [votes, setVotes] = useState([]);
  const [voteCounts, setVoteCounts] = useState({});
  const [totalVotes, setTotalVotes] = useState(0);

  useEffect(() => {
    const q = query(
      collection(db, 'feedback'),
      where('type', '==', 'payment_method_vote'),
      orderBy('timestamp', 'desc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const votesData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate()
      }));
      setVotes(votesData);

      // Calculate vote counts
      const counts = votesData.reduce((acc, vote) => {
        const method = vote.method.toLowerCase();
        acc[method] = (acc[method] || 0) + 1;
        return acc;
      }, {});

      // Sort by count
      const sortedCounts = Object.entries(counts)
        .sort(([,a], [,b]) => b - a)
        .reduce((acc, [key, value]) => ({
          ...acc,
          [key]: value
        }), {});

      setVoteCounts(sortedCounts);
      setTotalVotes(votesData.length);
    });

    return () => unsubscribe();
  }, []);

  const getPercentage = (count) => {
    return ((count / totalVotes) * 100).toFixed(1);
  };

  const getBarWidth = (count) => {
    return `${(count / totalVotes) * 100}%`;
  };

  return (
    <div className="payment-votes-dashboard">
      <div className="dashboard-header">
        <h2>Payment Method Preferences</h2>
        <div className="total-votes">Total Votes: {totalVotes}</div>
      </div>

      <div className="votes-chart">
        {Object.entries(voteCounts).map(([method, count]) => (
          <div key={method} className="vote-bar-container">
            <div className="vote-label">
              <span className="method-name">{method}</span>
              <span className="vote-count">
                {count} votes ({getPercentage(count)}%)
              </span>
            </div>
            <div className="vote-bar-wrapper">
              <div 
                className="vote-bar"
                style={{ width: getBarWidth(count) }}
              />
            </div>
          </div>
        ))}
      </div>

      <div className="recent-votes">
        <h3>Recent Votes</h3>
        <div className="votes-list">
          {votes.slice(0, 10).map(vote => (
            <div key={vote.id} className="vote-item">
              <div className="vote-method">{vote.method}</div>
              <div className="vote-time">
                {vote.timestamp?.toLocaleDateString()} {vote.timestamp?.toLocaleTimeString()}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PaymentVotesDashboard;
