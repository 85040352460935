// src/components/BuyerView.js

import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db, functions } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import './BuyerView.css';
import defaultProfile1 from '../assets/default-profile-1.png';
import { socialMediaIcons, getSocialMediaLink } from '../utils/socialMediaUtils';
import { calculateAverages, calculateSuggestedRates, calculateStoryViews } from '../utils/instagramUtils';
import ProgressBar from './ProgressBar';
import LoadingOverlay from './dashboard/LoadingOverlay';

function BuyerView() {
  const { username } = useParams();
  const navigate = useNavigate();
  const [seller, setSeller] = useState(null);
  const [selectedOffers, setSelectedOffers] = useState([]);
  const [requirements, setRequirements] = useState('');
  const [buyerEmail, setBuyerEmail] = useState('');
  const [buyerName, setBuyerName] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isCheckingOut, setIsCheckingOut] = useState(false);
  const [error, setError] = useState(null);
  const [estimatedReach, setEstimatedReach] = useState({ min: 0, max: 0 });
  const [progressWidth, setProgressWidth] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      
      try {
        // Get username document
        const usernameDocRef = doc(db, 'usernames', username.toLowerCase());
        const usernameDoc = await getDoc(usernameDocRef);
        
        if (!usernameDoc.exists()) {
          setError('Username not found');
          setIsLoading(false);
          return;
        }

        // Get seller document
        const sellerId = usernameDoc.data().uid;
        const sellerDocRef = doc(db, 'users', sellerId);
        const sellerDoc = await getDoc(sellerDocRef);

        if (!sellerDoc.exists()) {
          setError('Seller not found');
          setIsLoading(false);
          return;
        }

        // Set initial seller data
        const sellerData = { 
          id: sellerDoc.id, 
          ...sellerDoc.data(),
          // Default values for Instagram data
          followerCount: 1000, // Default follower count
          averages: {
            singlePhotoViews: 1000,
            carouselViews: 1200,
            reelViews: 1500,
            globalAverageViews: 1233,
          },
          suggestedRates: {
            'Instagram Reel': { min: 50, max: 70 },
            'Instagram Story': { min: 20, max: 30 },
            'Instagram Carousel Post': { min: 60, max: 80 },
            'Instagram Post': { min: 40, max: 60 },
          }
        };
        
        setSeller(sellerData);
        setIsLoading(false);

        // Fetch Instagram data if available
        if (sellerData.socialMedia?.instagram?.handle) {
          try {
            const [followerResult, postsResult] = await Promise.all([
              httpsCallable(functions, 'fetchInstagramData')({ username: sellerData.socialMedia.instagram.handle }),
              httpsCallable(functions, 'fetchRecentPosts')({ username: sellerData.socialMedia.instagram.handle })
            ]);

            if (!followerResult.data.error && !postsResult.data.error) {
              const processedPosts = postsResult.data.posts.map(post => ({
                ...post,
                estimatedViews: post.mediaType === 2 ? post.playCount : Math.round(post.likeCount / 0.03),
              }));

              const calculatedAverages = calculateAverages(processedPosts);
              const averagesWithDefaults = {
                singlePhotoViews: calculatedAverages.singlePhotoViews || 1000,
                carouselViews: calculatedAverages.carouselViews || 1200,
                reelViews: calculatedAverages.reelViews || 1500,
                globalAverageViews: calculatedAverages.globalAverageViews || 1233,
              };

              const suggestedRates = calculateSuggestedRates(
                averagesWithDefaults.globalAverageViews,
                averagesWithDefaults.reelViews,
                averagesWithDefaults.carouselViews,
                averagesWithDefaults.singlePhotoViews,
                followerResult.data.followers,
                processedPosts
              );

              setSeller(prev => ({
                ...prev,
                averages: averagesWithDefaults,
                suggestedRates,
                followerCount: followerResult.data.followers || 1000,
                posts: processedPosts
              }));
            }
          } catch (igError) {
            console.error('Instagram data fetch error:', igError);
            // Don't set error state here as it's not critical
          }
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load profile data');
        setIsLoading(false);
      }
    };

    if (username) {
      fetchData();
    }
  }, [username]);

  const handleOfferSelect = (offer) => {
    setSelectedOffers((prev) => {
      const existingOffer = prev.find((item) => item.name === offer.name);
      if (existingOffer) {
        return prev.map((item) =>
          item.name === offer.name
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        // Ensure price is a number before setting it as fixedPrice
        const fixedPrice = typeof offer.price === 'number' ? offer.price : parseFloat(offer.price) || 0;
        return [...prev, { ...offer, quantity: 1, fixedPrice }];
      }
    });
  };

  const handleQuantityChange = (offerName, change) => {
    setSelectedOffers((prev) => {
      return prev
        .map((offer) => {
          if (offer.name === offerName) {
            const newQuantity = Math.min(10, Math.max(0, offer.quantity + change));
            if (newQuantity === 0) {
              return null;
            }
            return { ...offer, quantity: newQuantity };
          }
          return offer;
        })
        .filter(Boolean);
    });
  };

  const totalPrice = useMemo(() => {
    return selectedOffers.reduce((total, offer) => {
      return total + offer.fixedPrice * offer.quantity;
    }, 0);
  }, [selectedOffers]);

  useEffect(() => {
    if (!seller?.averages) return;
  
    let totalViews = 0;
    
    selectedOffers.forEach((offer) => {
      if (offer.name.toLowerCase().includes('instagram')) {
        // Get the right view count based on content type
        let views = 0;
        if (offer.name.includes('Reel')) {
          views = seller.averages.reelViews;
        } else if (offer.name.includes('Carousel')) {
          views = seller.averages.carouselViews;
        } else if (offer.name.includes('Story')) {
          views = calculateStoryViews(seller.averages.globalAverageViews);
        } else {
          views = seller.averages.singlePhotoViews;
        }
        
        totalViews += views * offer.quantity;
      }
    });

    // Calculate min and max reach
    const minReach = Math.round(totalViews * 0.9);
    const maxReach = Math.round(totalViews * 1.1);
    
    setEstimatedReach({ min: minReach, max: maxReach });
    
    // Calculate progress width based on total potential reach
    const maxViews = seller.averages.globalAverageViews * 10; // Set maximum at 10x average views
    const progressPercentage = Math.min((totalViews / maxViews) * 100, 100);
    setProgressWidth(progressPercentage);
  }, [selectedOffers, seller]);

  // Email validation function
  const validateEmail = (email) => {
    // RFC 5322 compliant email regex
    const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return re.test(email.toLowerCase());
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setBuyerEmail(email);
    if (email && !validateEmail(email)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
    }
  };

  const handleNameChange = (e) => {
    const text = e.target.value;
    if (text.length <= 50) {
      setBuyerName(text);
    }
  };

  const handleCheckout = async () => {
    // Prevent double-clicks
    if (isCheckingOut) return;

    // Fast client-side validation with early returns
    if (!seller) {
      setError('Seller information not available');
      return;
    }
    if (!buyerEmail || !validateEmail(buyerEmail)) {
      setError('Please enter a valid email address');
      return;
    }
    if (!buyerName.trim()) {
      setError('Please enter your name');
      return;
    }
    if (!selectedOffers.length) {
      setError('Please select at least one offer');
      return;
    }
    if (!requirements.trim()) {
      setError('Please provide your requirements');
      return;
    }

    setIsCheckingOut(true);
    setError(null);

    try {
      const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');
      
      // Prepare offer data once before the API call
      const offerData = selectedOffers.map(offer => ({
        name: offer.name,
        price: offer.fixedPrice,
        quantity: offer.quantity,
      }));

      // Shorter timeout for better UX
      const checkoutResult = await Promise.race([
        createCheckoutSession({
          sellerId: seller.id,
          offers: offerData,
          requirements: requirements.trim(),
          buyerEmail: buyerEmail.trim(),
          buyerName: buyerName.trim(),
        }),
        new Promise((_, reject) => 
          setTimeout(() => reject(new Error('Request timed out')), 10000)
        )
      ]);

      // Navigate immediately after successful response
      if (checkoutResult.data.orderId) {
        navigate('/request-success', { 
          state: { 
            orderId: checkoutResult.data.orderId,
            creatorUsername: username 
          },
          replace: true // Prevent back navigation to checkout
        });
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Checkout error:', error);
      setError(
        error.message === 'Request timed out'
          ? 'Request timed out. Please try again.'
          : 'Unable to process request. Please try again.'
      );
    } finally {
      setIsCheckingOut(false);
    }
  };

  const isCheckoutReady =
    selectedOffers.length > 0 && 
    requirements.trim() && 
    buyerEmail.trim() && 
    buyerName.trim();

  return (
    <div className="buyer-view">
      {isLoading ? (
        <div className="loading-state">Loading...</div>
      ) : error ? (
        <div className="error-state">{error}</div>
      ) : (
        <>
          {isCheckingOut && (
            <LoadingOverlay 
              message="Processing your request..." 
              buyerName={buyerName}
            />
          )}
          <header>
            <img
              src={seller.profileImage || defaultProfile1}
              alt={seller.displayName}
              className="profile-image"
            />
            <h1 className="section-title">{seller.displayName}</h1>
            <div className="social-media-icons">
              {Object.entries(seller.socialMedia || {}).map(([platform, data]) =>
                data.handle ? (
                  <a
                    key={platform}
                    href={getSocialMediaLink(platform, data.handle)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={socialMediaIcons[platform]}
                      alt={platform}
                      className={`social-media-icon${platform.toLowerCase() === 'youtube' ? ' youtube' : ''}`}
                    />
                  </a>
                ) : null
              )}
            </div>
            <p>{seller.bio}</p>
          </header>

          {selectedOffers.length > 0 && 
           selectedOffers.some(offer => offer.name.toLowerCase().includes('instagram')) && 
           seller?.socialMedia?.instagram?.handle && (
            <ProgressBar
              estimatedReach={estimatedReach}
              progressWidth={progressWidth}
            />
          )}

          <section className="offers">
            {seller?.creatorOffers
              ?.filter(offer => offer.active !== false) // Only show active offers
              .map((offer, index) => (
                <div
                  key={index}
                  className="offer"
                  onClick={() => handleOfferSelect(offer)}
                >
                  <div className="offer-content">
                    <span className="offer-name">{offer.name}</span>
                    <span className="add-to-cart-text">Add to Cart</span>
                    <span className="offer-price">
                      ${parseFloat(offer.price).toFixed(2)}
                    </span>
                  </div>
                </div>
              ))}
          </section>

          {selectedOffers.length > 0 && (
            <section className="selected-offers">
              <h2 className="section-title">Selected Services</h2>
              {selectedOffers.map((offer) => (
                <div key={offer.name} className="selected-offer">
                  <div className="offer-details">
                    <p>{offer.name}</p>
                    <p className="offer-price">${offer.fixedPrice.toFixed(2)}</p>
                  </div>
                  <div className="quantity-control">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleQuantityChange(offer.name, -1);
                      }}
                      disabled={offer.quantity <= 1}
                    >
                      -
                    </button>
                    <span>{offer.quantity}</span>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleQuantityChange(offer.name, 1);
                      }}
                      disabled={offer.quantity >= 10}
                    >
                      +
                    </button>
                  </div>
                </div>
              ))}

              <div className="total-price">
                <h3 className="section-title">Total price: ${totalPrice.toFixed(2)}</h3>
              </div>

              <p className="order-note">
                Note: No payment required now. The creator will review your request first and you'll be notified to complete payment if accepted.
              </p>
            </section>
          )}

          {selectedOffers.length > 0 && (
            <section className="buyer-details">
              <h2 className="section-title">Add your details</h2>
              <input
                type="text"
                className="buyer-details-input"
                placeholder="Enter your name"
                value={buyerName}
                onChange={handleNameChange}
                maxLength={50}
                required
              />
              <input
                type="email"
                className="buyer-details-input"
                placeholder="Enter your email"
                value={buyerEmail}
                onChange={handleEmailChange}
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                required
              />
              {emailError && <div className="error-message">{emailError}</div>}

              <textarea
                className="buyer-details-input"
                placeholder="Tell us what you need! Include timeline, specific details, preferences, or examples if you have any."
                value={requirements}
                onChange={(e) => {
                  const text = e.target.value;
                  if (text.length <= 500) {
                    setRequirements(text);
                  }
                }}
                maxLength={500}
              />

              {/* Checkout Button */}
              <button
                onClick={handleCheckout}
                className={`checkout-button ${isCheckoutReady ? 'ready' : ''}`}
                disabled={!isCheckoutReady || isCheckingOut}
              >
                {isCheckingOut ? 'Processing...' : 'Send Request'}
              </button>
            </section>
          )}
          <button className="get-free-button" onClick={() => navigate('/')}>
            Get your Kaiju.bio free now
          </button>
        </>
      )}
    </div>
  );
}

export default BuyerView;
