import React from 'react';
import './LoadingOverlay.css';

const LoadingOverlay = ({ message, buyerName }) => {
  return (
    <div className="loading-overlay">
      <div className="loading-content">
        <div className="loading-spinner"></div>
        <div className="loading-message">
          {message}
          {buyerName && <div className="buyer-name">{buyerName}</div>}
        </div>
      </div>
    </div>
  );
};

export default LoadingOverlay;
