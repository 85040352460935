import React from 'react';
import { Link, useLocation, Navigate } from 'react-router-dom';
import { auth } from '../firebase';
import './RequestSuccessPage.css';

const RequestSuccessPage = () => {
  const location = useLocation();
  const { orderId, creatorUsername } = location.state || {};

  // If no state is present, redirect to home
  if (!location.state) {
    return <Navigate to="/" />;
  }

  return (
    <div className="success-page-container">
      <div className="success-page-content">
        <h1 className="success-page-title">
          Woohoo! Your Request is on its Way! 🚀
        </h1>
        
        <p className="success-page-subtitle">
          Get ready for something amazing! We've just pinged the creator about your request.
        </p>

        {orderId && (
          <div className="success-page-order-id">
            Order Reference: {orderId}
          </div>
        )}

        <div className="success-page-text">
          <p>We'll notify you once the creator responds to your request</p>
          <p>Keep an eye on your email for updates</p>
        </div>

        <div className="success-page-actions">
          <Link 
            to={`/${creatorUsername}`} 
            className="success-page-button back"
          >
            Back to Creator's Page
          </Link>

          {!auth.currentUser && (
            <div className="account-invitation">
              <h2>Join Our Creative Community</h2>
              <p>Create an account to discover and collaborate with amazing creators.</p>
              <Link 
                to="/signup" 
                className="create-account-button"
              >
                Create Free Account
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RequestSuccessPage;