import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, functions } from '../../firebase'; // Fix path to ../../firebase
import { httpsCallable } from 'firebase/functions';

function StripeReturn() {
  const [status, setStatus] = useState('Verifying your account...');
  const navigate = useNavigate();

  useEffect(() => {
    const verifyAccount = async () => {
      const user = auth.currentUser;
      if (!user) {
        setStatus('User not authenticated. Please log in.');
        setTimeout(() => navigate('/login'), 3000);
        return;
      }

      try {
        // Call the verifyStripeReturn function to check actual account status
        const verifyStripeReturn = httpsCallable(functions, 'verifyStripeReturn');
        const result = await verifyStripeReturn();

        if (result.data.isComplete) {
          setStatus('Stripe account setup complete!');
        } else {
          setStatus('Account setup incomplete. Please complete all required steps.');
        }

        // Redirect to dashboard after showing status
        setTimeout(() => navigate('/dashboard?section=payments'), 3000);

      } catch (error) {
        console.error('Error verifying account:', error);
        setStatus('An error occurred. Please try again.');
        setTimeout(() => navigate('/dashboard?section=payments'), 3000);
      }
    };

    verifyAccount();
  }, [navigate]);

  return (
    <div>
      <h1>Stripe Onboarding</h1>
      <p>{status}</p>
    </div>
  );
}

export default StripeReturn;