import React, { useState } from 'react';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import './FeedbackModal.css';

const FEEDBACK_TYPES = {
  FEATURE_REQUEST: 'Feature Request',
  BUG_REPORT: 'Bug Report',
  GENERAL_FEEDBACK: 'General Feedback',
  SUGGESTION: 'Suggestion'
};

const FeedbackModal = ({ isOpen, onClose, userId, userEmail }) => {
  const [feedbackType, setFeedbackType] = useState('');
  const [feedbackText, setFeedbackText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!feedbackType || !feedbackText.trim()) return;

    setIsSubmitting(true);
    try {
      await addDoc(collection(db, 'feedback'), {
        type: feedbackType,
        message: feedbackText.trim(),
        userId,
        userEmail,
        status: 'new',
        createdAt: serverTimestamp(),
        platform: 'web',
        userAgent: navigator.userAgent,
        priority: 'medium'
      });

      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        onClose();
        // Reset form
        setFeedbackType('');
        setFeedbackText('');
      }, 2000);
    } catch (error) {
      console.error('Error submitting feedback:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="feedback-modal-overlay">
      <div className="feedback-modal">
        <button className="close-button" onClick={onClose}>×</button>
        
        {showSuccess ? (
          <div className="success-message">
            <div className="success-icon">✓</div>
            <h3>Thank you for your feedback!</h3>
            <p>We appreciate your input and will use it to improve our platform.</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <h2>Share Your Feedback</h2>
            <p className="subtitle">Help us improve your experience</p>

            <div className="feedback-type-grid">
              {Object.entries(FEEDBACK_TYPES).map(([key, label]) => (
                <button
                  key={key}
                  type="button"
                  className={`feedback-type-button ${feedbackType === label ? 'selected' : ''}`}
                  onClick={() => setFeedbackType(label)}
                >
                  {label}
                </button>
              ))}
            </div>

            <div className="form-group">
              <label htmlFor="feedback">Your Feedback</label>
              <textarea
                id="feedback"
                value={feedbackText}
                onChange={(e) => setFeedbackText(e.target.value)}
                placeholder="Tell us what's on your mind..."
                rows="4"
                required
              />
            </div>

            <div className="modal-actions">
              <button 
                type="button" 
                className="secondary-button"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="primary-button"
                disabled={isSubmitting || !feedbackType || !feedbackText.trim()}
              >
                {isSubmitting ? 'Submitting...' : 'Submit Feedback'}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default FeedbackModal;
