// src/components/InstagramStats.js

import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { fetchInstagramDataWithCache, calculatePricing, calculateStoryViews } from '../utils/instagramUtils';
import { useNavigate } from 'react-router-dom';
import './InstagramStats.css';
import ProgressBar from './ProgressBar';

// List of admin emails (keep in sync with Admin.js)
const ADMIN_EMAILS = ['hi@kaiju.bio', 'martawithat@gmail.com'];

function InstagramStats() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [followerCount, setFollowerCount] = useState(null);
  const [recentPosts, setRecentPosts] = useState([]);
  const [averages, setAverages] = useState({});
  const [suggestedRates, setSuggestedRates] = useState({});
  const [engagementRates, setEngagementRates] = useState({ overall: 0, reels: 0, photos: 0 });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('instagram');
  const [tiktokFollowers, setTiktokFollowers] = useState('');
  const [youtubeFollowers, setYoutubeFollowers] = useState('');
  const [tiktokRates, setTiktokRates] = useState(null);
  const [youtubeRates, setYoutubeRates] = useState(null);
  const [tiktokEngagement, setTiktokEngagement] = useState('3'); // Default 3%
  const [youtubeEngagement, setYoutubeEngagement] = useState('2'); // Default 2%
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [estimatedReach, setEstimatedReach] = useState({ min: 0, max: 0 });
  const [cartTotal, setCartTotal] = useState(0);
  const [totalEstimatedViews, setTotalEstimatedViews] = useState(0);
  const [progressWidth, setProgressWidth] = useState(0);

  const products = [
    { id: 1, name: 'Instagram Post', type: 1 },
    { id: 2, name: 'Instagram Carousel', type: 8 },
    { id: 3, name: 'Instagram Reel', type: 2 },
    { id: 4, name: 'Instagram Story', type: 'story' },
  ];

  const fetchInstagramData = async () => {
    setLoading(true);
    setError(null);
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      setError('You must be logged in to fetch Instagram data.');
      setLoading(false);
      return;
    }

    try {
      const functions = getFunctions();
      const fetchInstagramDataFunction = httpsCallable(functions, 'fetchInstagramData');
      const fetchRecentPostsFunction = httpsCallable(functions, 'fetchRecentPosts');
      
      const result = await fetchInstagramDataWithCache(username, { 
        httpsCallable, 
        functions,
        fetchInstagramDataFunction,
        fetchRecentPostsFunction
      }, true);

      setFollowerCount(result.followerCount);
      setRecentPosts(result.posts);
      setAverages(result.averages);
      setEngagementRates(result.engagement);
      setSuggestedRates(result.suggestedRates);

    } catch (error) {
      console.error('Error fetching Instagram data:', error);
      setError('Failed to fetch Instagram data');
    } finally {
      setLoading(false);
    }
  };

  const calculatePlatformRates = (platform, followers, engagement) => {
    if (!followers || isNaN(followers)) return null;
    
    const followerCount = parseInt(followers);
    const engagementRate = parseFloat(engagement);
    
    if (platform === 'tiktok') {
      return {
        'Video': calculatePricing('tiktok', 'video', followerCount, engagementRate)
      };
    } else {
      return {
        'Video': calculatePricing('youtube', 'video', followerCount, engagementRate)
      };
    }
  };

  const handleTikTokCalculate = () => {
    const rates = calculatePlatformRates('tiktok', tiktokFollowers, tiktokEngagement);
    setTiktokRates(rates);
  };

  const handleYouTubeCalculate = () => {
    const rates = calculatePlatformRates('youtube', youtubeFollowers, youtubeEngagement);
    setYoutubeRates(rates);
  };

  const addToCart = (product) => {
    const suggestedPrice = suggestedRates[product.name]?.min || 0;
    setSelectedProducts([...selectedProducts, { ...product, price: suggestedPrice }]);
  };

  const removeFromCart = (index) => {
    const newSelectedProducts = [...selectedProducts];
    newSelectedProducts.splice(index, 1);
    setSelectedProducts(newSelectedProducts);
  };

  const updateProductPrice = (index, newPrice) => {
    const newSelectedProducts = [...selectedProducts];
    newSelectedProducts[index].price = parseFloat(newPrice) || 0;
    setSelectedProducts(newSelectedProducts);
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user || !ADMIN_EMAILS.includes(user.email)) {
        navigate('/');
        return;
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (!averages.globalAverageViews) return;

    let views = 0;
    let total = 0;

    selectedProducts.forEach(product => {
      switch (product.type) {
        case 1: // Single Photo
          views += averages.singlePhotoViews || averages.globalAverageViews;
          break;
        case 8: // Carousel
          views += averages.carouselViews || averages.globalAverageViews;
          break;
        case 2: // Reel
          views += averages.reelViews || averages.globalAverageViews;
          break;
        case 'story': // Story
          views += calculateStoryViews(averages.globalAverageViews);
          break;
        default:
          views += averages.globalAverageViews;
      }
      total += product.price;
    });

    // Calculate estimated reach with ±10% range
    setEstimatedReach({ 
      min: Math.round(views * 0.9), 
      max: Math.round(views * 1.1) 
    });
    setCartTotal(total);
    setTotalEstimatedViews(Math.round(views));

    // Calculate progress width based on total potential reach
    const maxViews = averages.globalAverageViews * 10; // Set maximum at 10x average views
    const progressPercentage = Math.min((views / maxViews) * 100, 100);
    setProgressWidth(progressPercentage);
  }, [selectedProducts, averages, followerCount]);

  return (
    <div className="instagram-stats-container">
      <div className="platform-tabs">
        <button 
          className={`tab-button ${activeTab === 'instagram' ? 'active' : ''}`}
          onClick={() => setActiveTab('instagram')}
        >
          Instagram
        </button>
        <button 
          className={`tab-button ${activeTab === 'tiktok' ? 'active' : ''}`}
          onClick={() => setActiveTab('tiktok')}
        >
          TikTok
        </button>
        <button 
          className={`tab-button ${activeTab === 'youtube' ? 'active' : ''}`}
          onClick={() => setActiveTab('youtube')}
        >
          YouTube
        </button>
      </div>

      {activeTab === 'instagram' && (
        <>
          <h1>Instagram Stats</h1>
          <div className="input-section">
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter Instagram username"
            />
            <button onClick={fetchInstagramData} disabled={loading || !username}>
              {loading ? 'Loading...' : 'Fetch Instagram Data'}
            </button>
          </div>
          {error && <p className="error-message">{error}</p>}
          {followerCount !== null && (
            <div className="stats-section">
              <h2>Account Statistics</h2>
              <div className="stats-grid">
                <div className="stat-item">
                  <span className="stat-label">Follower Count</span>
                  <span className="stat-value">{followerCount.toLocaleString()}</span>
                </div>
                <div className="stat-item">
                  <span className="stat-label">Average Views per Post</span>
                  <span className="stat-value">{averages.globalAverageViews?.toLocaleString()}</span>
                </div>
                <div className="stat-item">
                  <span className="stat-label">Average Image Views</span>
                  <span className="stat-value">{averages.singlePhotoViews?.toLocaleString() || 'N/A'}</span>
                </div>
                <div className="stat-item">
                  <span className="stat-label">Average Carousel Views</span>
                  <span className="stat-value">{averages.carouselViews?.toLocaleString() || 'N/A'}</span>
                </div>
                <div className="stat-item">
                  <span className="stat-label">Average Reel Plays</span>
                  <span className="stat-value">{averages.reelViews?.toLocaleString() || 'N/A'}</span>
                </div>
              </div>
              <div className="engagement-rates">
                <p>Engagement Rates:</p>
                <p className="engagement-rate">Overall: {(engagementRates.overall || 0).toFixed(2)}%</p>
                <p className="engagement-rate">Reels: {(engagementRates.reels || 0).toFixed(2)}%</p>
                <p className="engagement-rate">Photos: {(engagementRates.photos || 0).toFixed(2)}%</p>
              </div>
              
              <h2>Suggested Rates</h2>
              <div className="platform-rates">
                <div className="platform-section">
                  <div className="rate-item">
                    <span>Post:</span>
                    <span>${suggestedRates['Instagram Post']?.min.toLocaleString()} - ${suggestedRates['Instagram Post']?.max.toLocaleString()}</span>
                  </div>
                  <div className="rate-item">
                    <span>Carousel:</span>
                    <span>${suggestedRates['Instagram Carousel']?.min.toLocaleString()} - ${suggestedRates['Instagram Carousel']?.max.toLocaleString()}</span>
                  </div>
                  <div className="rate-item">
                    <span>Story:</span>
                    <span>${suggestedRates['Instagram Story']?.min.toLocaleString()} - ${suggestedRates['Instagram Story']?.max.toLocaleString()}</span>
                  </div>
                  <div className="rate-item">
                    <span>Reel:</span>
                    <span>${suggestedRates['Instagram Reel']?.min.toLocaleString()} - ${suggestedRates['Instagram Reel']?.max.toLocaleString()}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {activeTab === 'tiktok' && (
        <div className="platform-input-section">
          <h1>TikTok Calculator</h1>
          <div className="input-group">
            <div className="input-field">
              <label>Number of Followers:</label>
              <input
                type="number"
                value={tiktokFollowers}
                onChange={(e) => setTiktokFollowers(e.target.value)}
                placeholder="Enter follower count"
              />
            </div>
            <div className="input-field">
              <label>Engagement Rate (%):</label>
              <input
                type="number"
                value={tiktokEngagement}
                onChange={(e) => setTiktokEngagement(e.target.value)}
                placeholder="Enter engagement rate"
                step="0.1"
              />
            </div>
            <button onClick={handleTikTokCalculate}>Calculate Rates</button>
          </div>
          
          {tiktokRates && (
            <div className="platform-rates">
              <div className="platform-section">
                <h3>Suggested Rates</h3>
                <div className="rate-item">
                  <span>Video:</span>
                  <span>${tiktokRates['Video']?.min.toLocaleString()} - ${tiktokRates['Video']?.max.toLocaleString()}</span>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {activeTab === 'youtube' && (
        <div className="platform-input-section">
          <h1>YouTube Calculator</h1>
          <div className="input-group">
            <div className="input-field">
              <label>Number of Subscribers:</label>
              <input
                type="number"
                value={youtubeFollowers}
                onChange={(e) => setYoutubeFollowers(e.target.value)}
                placeholder="Enter subscriber count"
              />
            </div>
            <div className="input-field">
              <label>Engagement Rate (%):</label>
              <input
                type="number"
                value={youtubeEngagement}
                onChange={(e) => setYoutubeEngagement(e.target.value)}
                placeholder="Enter engagement rate"
                step="0.1"
              />
            </div>
            <button onClick={handleYouTubeCalculate}>Calculate Rates</button>
          </div>
          
          {youtubeRates && (
            <div className="platform-rates">
              <div className="platform-section">
                <h3>Suggested Rates</h3>
                <div className="rate-item">
                  <span>Video:</span>
                  <span>${youtubeRates['Video']?.min.toLocaleString()} - ${youtubeRates['Video']?.max.toLocaleString()}</span>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <div className="products-section">
        <h2>Products</h2>
        {products.map(product => (
          <div key={product.id} className="product-item">
            <span>{product.name}</span>
            {suggestedRates[product.name] && (
              <span className="suggested-price">
                Suggested Price: ${suggestedRates[product.name].min.toLocaleString()} - ${suggestedRates[product.name].max.toLocaleString()}
              </span>
            )}
            <button onClick={() => addToCart(product)}>Add to Cart</button>
          </div>
        ))}
      </div>
      <div className="cart-section">
        <h2>Cart</h2>
        {selectedProducts.map((product, index) => (
          <div key={index} className="cart-item">
            <span>{product.name}</span>
            <input
              type="number"
              value={product.price}
              onChange={(e) => updateProductPrice(index, e.target.value)}
              min="0"
              step="0.01"
            />
            <button onClick={() => removeFromCart(index)}>Remove</button>
          </div>
        ))}
        <p>Total Amount: ${cartTotal.toFixed(2)}</p>
        <p>Total Estimated Views/Plays: {totalEstimatedViews.toLocaleString()}</p>
      </div>

      {selectedProducts.length > 0 && (
        <ProgressBar
          estimatedReach={estimatedReach}
          progressWidth={progressWidth}
        />
      )}

      {recentPosts.length > 0 && (
        <div className="recent-posts-section">
          <h2>Recent Posts</h2>
          <ul>
            {recentPosts.map((post, index) => (
              <li key={index} className="post-item">
                <p>Media Type: {post.mediaType === 1 ? 'Image' : post.mediaType === 2 ? 'Reel' : post.mediaType === 8 ? 'Carousel' : 'Unknown'}</p>
                <p>Shares: {post.shareCount}</p>
                <p>Likes: {post.likeCount}</p>
                <p>Comments: {post.commentCount}</p>
                {post.mediaType === 2 ? (
                  <p>Plays: {post.playCount.toLocaleString()}</p>
                ) : (
                  <p>Estimated Views: {post.estimatedViews.toLocaleString()}</p>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default InstagramStats;
