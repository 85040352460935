// PurchasesSection.js
import React from 'react';
import './PurchasesSection.css';

function PurchasesSection() {
  return (
    <div className="purchases-section">
      <div className="coming-soon-container">
        <h2>Purchases History</h2>
        <div className="coming-soon-content">
          <span className="coming-soon-badge">Coming Soon</span>
          <p>Track all your content purchases in one place.</p>
        </div>
      </div>
    </div>
  );
}

export default PurchasesSection;
