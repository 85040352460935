import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Homepage from './components/Homepage';
import Signup from './components/Signup';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import ClaimName from './components/ClaimName';
import AddSocialMedia from './components/AddSocialMedia';
import FinishProfile from './components/FinishProfile';
import BuyerView from './components/BuyerView'; 
import StripeOnboardingComplete from './components/dashboard/StripeOnboardingComplete';
import StripeReturn from './components/dashboard/StripeReturn';
import RequestAcceptedPage from './components/RequestAcceptedPage';
import SellerConnectButton from './components/dashboard/SellerConnectButton';
import RequestSuccessPage from './components/RequestSuccessPage';
import InstagramStats from './components/InstagramStats'; // Import the new component
import PaymentSuccess from './components/PaymentSuccess';
import Admin from './components/admin/Admin';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }
 
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<Homepage />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/:username" element={<BuyerView />} />
          <Route path="/request-accepted" element={<RequestAcceptedPage />} />
          <Route path="/request-success" element={<RequestSuccessPage />} />
          <Route path="/instagram-stats" element={<InstagramStats />} /> {/* Add this new route */}
          <Route path="/payment-success" element={<PaymentSuccess />} />

          {/* Protected routes */}
          <Route path="/dashboard" element={user ? <Dashboard /> : <Navigate to="/login" />} />
          <Route path="/claim-name" element={user ? <ClaimName /> : <Navigate to="/login" />} />
          <Route path="/add-social-media" element={user ? <AddSocialMedia /> : <Navigate to="/login" />} />
          <Route path="/finish-profile" element={user ? <FinishProfile /> : <Navigate to="/login" />} />
          <Route path="/connect" element={user ? <SellerConnectButton /> : <Navigate to="/login" />} />
          <Route path="/stripe-onboarding-complete" element={user ? <StripeOnboardingComplete /> : <Navigate to="/login" />} />
          <Route path="/stripe-return" element={user ? <StripeReturn /> : <Navigate to="/login" />} />
          <Route path="/admin" element={user ? <Admin /> : <Navigate to="/login" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;