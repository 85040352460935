import React, { useState } from 'react';
import { connectToStripe } from '../../utils/stripeUtils';
import '../dashboard/SellerConnectButton.css';

function SellerConnectButton({ sellerId, stripeAccountSetup }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleStripeAction = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await connectToStripe();
    } catch (err) {
      setError(err.message || 'An error occurred while connecting to Stripe');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="stripe-connection-status">
      <button onClick={handleStripeAction} disabled={isLoading} className="stripe-action-btn">
        {isLoading ? 'Processing...' : (stripeAccountSetup ? 'Edit Payment Details' : 'Connect with Stripe')}
      </button>
      {error && <p className="error-message">{error}</p>}
      <div className={`status-indicator ${stripeAccountSetup ? 'connected' : 'disconnected'}`}></div>
    </div>
  );
}

export default SellerConnectButton;