// src/components/dashboard/PreviewPanel.js
import React from 'react';
import { Link } from 'react-router-dom';
import defaultProfile1 from '../../assets/default-profile-1.png';
import './PreviewPanel.css';
import { socialMediaIcons } from '../../utils/socialMediaUtils';

const PreviewPanel = ({ userProfile, creatorOffers }) => {
  return (
    <div className="preview-panel">
      <div className="preview-header">
        <h3>Preview</h3>
        <Link 
          to={`/${userProfile.username}`} 
          target="_blank" 
          className="preview-open-button"
        >
          View live →
        </Link>
      </div>
      
      <div className="preview-phone-container">
        <div className="preview-phone">
          <div className="preview-phone-content">
            <header className="preview-buyer-header">
              <img
                src={userProfile.profileImage || defaultProfile1}
                alt={userProfile.displayName}
                className="preview-profile-image"
              />
              <div className="preview-profile-name">{userProfile.displayName || 'Creator'}</div>
              <div className="preview-profile-subtitle">
                {userProfile.bio || 'Add your bio'}
              </div>

              <div className="preview-social-media-icons">
                {Object.entries(userProfile.socialMedia || {}).map(([platform, data]) =>
                  data?.handle ? (
                    <img
                      key={platform}
                      src={socialMediaIcons[platform]}
                      alt={platform}
                      className={`preview-social-icon${platform.toLowerCase() === 'youtube' ? ' youtube' : ''}`}
                    />
                  ) : null
                )}
              </div>
            </header>

            <section className="preview-offers">
              {creatorOffers
                .filter(offer => offer.active !== false) // Only show active offers
                .map((offer, index) => (
                  <div key={index} className="preview-offer">
                    <div className="preview-offer-content">
                      <span className="preview-offer-name">{offer.name}</span>
                      <span className="preview-add-to-cart">Add to Cart</span>
                      <span className="preview-offer-price">
                        {offer.price ? `$${parseFloat(offer.price).toFixed(2)}` : ''}
                      </span>
                    </div>
                  </div>
                ))}
            </section>

           
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewPanel;