import React, { useState, useEffect, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './CreatorOffersSection.css';

const CreatorOffersSection = ({ 
  creatorOffers, 
  suggestedPrices, 
  handleRemove, 
  handlePriceChange, 
  handleSelect, 
  contentTypes,
  moreContentTypes,
  handleSaveChanges,
  unsavedChanges,
  isSaving,
  setUnsavedChanges
}) => {
  const [showAvailableServices, setShowAvailableServices] = useState(false);
  const [showPriceError, setShowPriceError] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [customOfferName, setCustomOfferName] = useState('');
  const [showCustomProductInput, setShowCustomProductInput] = useState(false);
  const [isDragDisabled, setIsDragDisabled] = useState(true);
  const [editingOffer, setEditingOffer] = useState(null);
  const menuRef = useRef(null);

  useEffect(() => {
    // Enable drag after component is mounted
    setIsDragDisabled(false);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowAvailableServices(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Combine all content types into one array
  const allContentTypes = [...contentTypes, ...moreContentTypes];
  const availableContent = allContentTypes.filter(
    content => !creatorOffers.some(offer => offer.name === content.name)
  );

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(creatorOffers);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Update the offers with the new order and trigger save
    handlePriceChange(null, { updatedOffers: items });
    setUnsavedChanges(true);
  };

  const handleServiceSelect = (content) => {
    const newOffer = {
      ...content,
      active: true,  // Ensure active is true for any new offer
      price: content.price || ''
    };
    
    handleSelect(newOffer);
    setShowPriceError(false);
    setShowAvailableServices(false);
  };

  const handleCustomServiceAdd = () => {
    if (!customOfferName.trim()) return;
    
    const newOffer = {
      name: customOfferName,
      type: 'custom',
      isCustom: true,
      price: '',
      active: true  // Set active to true by default
    };
    handleServiceSelect(newOffer);
    setCustomOfferName('');
    setShowCustomProductInput(false);
  };

  const handleCustomTitleChange = (offer, newTitle) => {
    const updatedOffers = creatorOffers.map(o => {
      if (o === offer) {
        return {
          ...o,
          name: newTitle,
          isCustom: true,
          active: o.active ?? true  // Maintain active state, default to true if not set
        };
      }
      return o;
    });
    handlePriceChange(offer.name, {
      name: newTitle,
      updatedOffers
    });
  };

  const handleCustomTitleSave = (offer, newTitle) => {
    handleCustomTitleChange(offer, newTitle);
    setEditingOffer(null);
  };

  const handleEditClick = (offer) => {
    setEditingOffer(offer.name);
  };

  const toggleOfferActive = (offerName) => {
    const offer = creatorOffers.find(o => o.name === offerName);
    if (!offer) return;
    
    const updatedOffers = creatorOffers.map(o => 
      o.name === offerName ? { ...o, active: !offer.active } : o
    );
    handlePriceChange(offerName, {
      active: !offer.active,
      updatedOffers
    });
  };

  const handleOfferRemove = (offerName) => {
    handleRemove(offerName);
  };

  const onSaveClick = async () => {
    const hasUnpricedOffers = creatorOffers.some(offer => !offer.price);
    if (hasUnpricedOffers) {
      setShowPriceError(true);
      return;
    }
    setShowPriceError(false);
    
    try {
      await handleSaveChanges();
      setSaveSuccess(true);
      setTimeout(() => {
        setSaveSuccess(false);
      }, 2000);
    } catch (error) {
      setSaveSuccess(false);
    } finally {
      setSaveSuccess(false);
    }
  };

  const onPriceUpdate = (offerId, price) => {
    const updatedOffers = creatorOffers.map(offer => {
      if (offer.name === offerId) {
        return {
          ...offer,
          price: price
        };
      }
      return offer;
    });
    handlePriceChange(offerId, { price, updatedOffers });
    setShowPriceError(false);
  };

  const renderOffer = (offer, index) => {
    const isCustom = offer.isCustom || offer.type === 'custom';
    
    return (
      <Draggable 
        key={offer.name} 
        draggableId={offer.name} 
        index={index}
        isDragDisabled={isDragDisabled}
      >
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className="creator-offer-item"
          >
            <div className="creator-offer-details">
              <div {...provided.dragHandleProps} className="drag-handle">
                ⋮⋮
              </div>
              {isCustom && editingOffer === offer.name ? (
                <div className="creator-title-edit-container">
                  <textarea
                    className="creator-custom-title-input"
                    value={offer.name}
                    onChange={(e) => handleCustomTitleChange(offer, e.target.value)}
                    placeholder="Enter custom offer details"
                    rows="2"
                    autoFocus
                    onBlur={(e) => handleCustomTitleSave(offer, e.target.value)}
                  />
                </div>
              ) : (
                <div className="creator-offer-title-container">
                  <div className="creator-offer-title">{offer.name}</div>
                  {suggestedPrices && suggestedPrices[offer.name] && (
                    <div className="creator-suggested-price">
                      Suggested price: ${suggestedPrices[offer.name].min?.toLocaleString()} - ${suggestedPrices[offer.name].max?.toLocaleString()}
                    </div>
                  )}
                  {isCustom && (
                    <button 
                      className="creator-edit-btn"
                      onClick={() => handleEditClick(offer)}
                    >
                      <svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                      </svg>
                    </button>
                  )}
                </div>
              )}
              <div className="creator-price-section">
                <div className="creator-price-input-container">
                  <span className="creator-currency-symbol">$</span>
                  <input
                    type="number"
                    value={offer.price}
                    onChange={(e) => onPriceUpdate(offer.name, e.target.value)}
                    className="creator-price-input"
                    min="0"
                    step="0.01"
                    placeholder="0.00"
                  />
                </div>
              </div>
              <div className="creator-offer-controls">
                <label className="creator-toggle-container">
                  <input
                    type="checkbox"
                    checked={offer.active}
                    onChange={() => toggleOfferActive(offer.name)}
                    className="creator-toggle-input"
                  />
                  <span className="creator-toggle-slider"></span>
                </label>
                <button
                  className="creator-remove-btn"
                  onClick={() => handleOfferRemove(offer.name)}
                  title="Remove offer"
                >
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M18 6L6 18M6 6l12 12"></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        )}
      </Draggable>
    );
  };

  const toggleCustomProductInput = () => {
    setShowCustomProductInput(!showCustomProductInput);
    setCustomOfferName('');
  };

  return (
    <div className="creator-dashboard-section">
      <div className="services-container">
        <button
          className="add-service-button"
          onClick={() => setShowAvailableServices(!showAvailableServices)}
        >
          + Add Service
        </button>

        <div className={`creator-available-content ${showAvailableServices ? 'show' : ''}`} ref={menuRef}>
          <div className="platform-services">
            {availableContent.map((content) => (
              <button
                key={content.id}
                className="creator-add-btn"
                onClick={() => handleServiceSelect(content)}
              >
                {content.name}
              </button>
            ))}
            <button
              onClick={toggleCustomProductInput}
              className="creator-add-btn custom-product"
            >
              + Add Custom Service
            </button>
          </div>
          
          {showCustomProductInput && (
            <div className="platform-services-custom">
              <div className="custom-offer-container">
                <input
                  type="text"
                  value={customOfferName}
                  onChange={(e) => setCustomOfferName(e.target.value)}
                  placeholder="Enter custom service name"
                  className="custom-offer-input"
                  onKeyPress={(e) => e.key === 'Enter' && handleCustomServiceAdd()}
                  autoFocus
                />
                <div className="custom-product-actions">
                  <button
                    onClick={handleCustomServiceAdd}
                    className="creator-add-btn"
                    disabled={!customOfferName.trim()}
                  >
                    Add Service
                  </button>
                  <button
                    onClick={toggleCustomProductInput}
                    className="creator-add-btn cancel-btn"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <DragDropContext onDragEnd={handleDragEnd}>
        {!isDragDisabled && (
          <Droppable droppableId="creator-offers">
            {(provided) => (
              <div 
                className="creator-offers-list"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {creatorOffers.map((offer, index) => renderOffer(offer, index))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
      </DragDropContext>

      {showAvailableServices && (
        <div 
          className="creator-services-overlay" 
          onClick={() => setShowAvailableServices(false)} 
        />
      )}

      {showPriceError && (
        <div className="price-error-message">
          Enter a price for all offers. Unpriced offers won't be saved
        </div>
      )}

      {unsavedChanges && (
        <button 
          className={`creator-save-btn ${saveSuccess ? 'success' : ''} ${isSaving ? 'saving' : ''}`}
          onClick={onSaveClick}
          disabled={isSaving}
        >
          <span className="button-text">
            {isSaving ? 'Saving...' : 'Save Changes'}
          </span>
          <span className="checkmark">✓</span>
        </button>
      )}
    </div>
  );
};

export default CreatorOffersSection;