import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import './ClaimName.css';
import { checkReservedUsername } from '../utils/reservedUsernames';

const USERNAME_MIN_LENGTH = 3;
const USERNAME_MAX_LENGTH = 30;

function ClaimName() {
  const [username, setUsername] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const formatUsername = (input) => {
    return input.replace(/[^a-zA-Z0-9-\s]/g, '')
               .replace(/\s+/g, '-')
               .toLowerCase();
  };

  const handleUsernameChange = (e) => {
    const formattedUsername = formatUsername(e.target.value);
    if (formattedUsername.length <= USERNAME_MAX_LENGTH) {
      setUsername(formattedUsername);
      setError(null);
      setSuccess(false);
    }
  };

  const checkUsernameAvailability = async (username) => {
    if (username.length < USERNAME_MIN_LENGTH || username.length > USERNAME_MAX_LENGTH) {
      setError('Username must be between 3 and 30 characters');
      setSuccess(false);
      return false;
    }

    // Check if it's a reserved username
    const reservedCheck = checkReservedUsername(username);
    if (reservedCheck.isReserved) {
      setError('This username is not available');
      setSuccess(false);
      return false;
    }

    // Check if username is already taken
    const docRef = doc(db, "usernames", username.toLowerCase());
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      setError('This username is not available');
      setSuccess(false);
      return false;
    }

    setSuccess(true);
    setError(null);
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    const isAvailable = await checkUsernameAvailability(username);
    if (!isAvailable) return;

    try {
      // Username is available, proceed with saving
      const user = auth.currentUser;
      if (user) {
        await setDoc(doc(db, "usernames", username.toLowerCase()), { uid: user.uid });
        await setDoc(doc(db, "users", user.uid), { 
          username: username,
          createdAt: new Date()
        }, { merge: true });

        localStorage.setItem('isOnboarding', 'true');
        navigate('/add-social-media');
      }
    } catch (error) {
      console.error("Error:", error);
      setError("An error occurred. Please try again.");
    }
  };

  return (
    <div className="kaiju-claim-container">
      <button onClick={() => navigate(-1)} className="kaiju-claim-back-button">&lt; back</button>
      <h1 className="kaiju-claim-title">Grab your username</h1>
      {error && <p className="kaiju-claim-error">{error}</p>}
      {success && <p className="kaiju-claim-success">Username is available!</p>}
      
      <form onSubmit={handleSubmit} className="kaiju-claim-form">
        <div className="kaiju-claim-input-group">
          <div className="kaiju-claim-input-wrapper">
            <span className="kaiju-claim-prefix">kaiju.bio/</span>
            <input
              type="text"
              value={username}
              onChange={handleUsernameChange}
              required
              className="kaiju-claim-input"
              placeholder="username"
              maxLength={USERNAME_MAX_LENGTH}
            />
          </div>
        </div>
        <button 
          type="submit" 
          className="continue-button"
        >
          Claim
        </button>
      </form>
    </div>
  );
}

export default ClaimName;