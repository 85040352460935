import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';

export const connectToStripe = async () => {
  try {
    const createStripeAccount = httpsCallable(functions, 'createStripeAccount');
    const result = await createStripeAccount({ country: 'US' });
    
    if (result.data && result.data.url) {
      return result.data;
    } else {
      throw new Error('No Stripe account link URL received');
    }
  } catch (err) {
    console.error('Error connecting to Stripe:', err);
    throw err;
  }
};