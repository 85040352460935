// Categories of reserved usernames for different error messages
const reservedUsernames = {
    // Brand names - "This username is reserved for the brand"
    brands: [
        // Tech Companies
        'apple', 'microsoft', 'google', 'amazon', 'meta', 'facebook', 'instagram', 'twitter', 'x',
        'linkedin', 'tiktok', 'snapchat', 'pinterest', 'youtube', 'netflix', 'spotify',

        // Fashion & Luxury Brands
        'nike', 'adidas', 'puma', 'reebok', 'underarmour', 'gucci', 'louisvuitton', 'chanel',
        'hermes', 'zara', 'hm', 'uniqlo', 'supreme', 'vans', 'converse',

        // Sports
        'nba', 'fifa', 'nfl', 'mlb', 'nhl', 'ufc', 'espn',

        // Entertainment
        'disney', 'warner', 'netflix', 'universal', 'paramount', 'sony', 'marvel', 'pixar',
        'nintendo', 'playstation', 'xbox',

        // Food & Beverage
        'cocacola', 'pepsi', 'starbucks', 'mcdonalds', 'subway', 'burgerking',

        // Automotive
        'toyota', 'honda', 'ford', 'bmw', 'mercedes', 'tesla', 'ferrari', 'porsche',
    ],

    // Creator names - "This username is reserved for a verified creator"
    creators: [
        // YouTube
        'pewdiepie', 'mrbeast', 'markiplier', 'jacksepticeye', 'jeffreestar', 'caseyneist', 
        'dude_perfect', 'nastya', 'jamescharles', 'daviddobrik', 'loganpaul', 'ksi',

        // TikTok
        'charlidamelio', 'kilmaruig','kilmaru', 'khaby', 'khabylame', 'bellapoarch', 'addisonrae', 'zachking',
        'spencerx', 'lorengray', 'joshrichards', 'riyaz', 'justmaiko',

        // Instagram
        'cristiano', 'leomessi', 'kyliejenner', 'selenagomez', 'therock', 'kimkardashian',
        'arianagrande', 'beyonce', 'kendalljenner', 'taylorswift', 'jlo',

        // Twitch
        'ninja', 'pokimane', 'shroud', 'auronplay', 'rubius', 'ibai', 'xqc', 'tfue',
        'sodapoppin', 'adinross', 'amouranth', 'nickmercs'
    ],

    // System names - "This username is reserved for system use"
    system: [
        // Admin and system pages
        'admin', 'administrator', 'mod', 'moderator', 'support', 'help', 'info',
        'system', 'official', 'staff', 'team', 'security', 'verified',
        'payment', 'billing', 'account', 'settings', 'profile', 'dashboard',

        // App routes and features
        'login', 'signup', 'register', 'signin', 'signout', 'logout',
        'claim-name', 'claimname',
        'add-social-media', 'addsocialmedia',
        'finish-profile', 'finishprofile',
        'connect', 'stripe-connect',
        'stripe-onboarding-complete', 'stripeonboardingcomplete',
        'stripe-return', 'stripereturn',
        'payment-success', 'paymentsuccess',
        'request-accepted', 'requestaccepted',
        'request-success', 'requestsuccess',
        'instagram-stats', 'instagramstats',
        'home', 'homepage', 'index',
        'api', 'docs', 'documentation',
        'terms', 'privacy', 'about', 'contact',
        'notifications', 'messages', 'inbox',
        'orders', 'payments', 'transactions',
        'settings', 'preferences',
        'search', 'explore', 'discover',
        'feed', 'trending', 'popular',
        'categories', 'tags',
        'kaiju', 'bio'
    ]
};

// Helper function to check if a username is reserved and get the category
export function checkReservedUsername(username) {
    const lowercaseUsername = username.toLowerCase();

    // Check brands
    if (reservedUsernames.brands.includes(lowercaseUsername)) {
        return {
            isReserved: true,
            message: 'This username is not available'
        };
    }

    // Check creators
    if (reservedUsernames.creators.includes(lowercaseUsername)) {
        return {
            isReserved: true,
            message: 'This username is not available'
        };
    }

    // Check system names
    if (reservedUsernames.system.includes(lowercaseUsername)) {
        return {
            isReserved: true,
            message: 'This username is not available'
        };
    }

    return {
        isReserved: false,
        message: ''
    };
}

export default reservedUsernames;
